import {
  Box,
  Container,
  Grid,
  Stack,
  Typography,
  IconButton,
  Button,
} from '@mui/material';
import React, { useState, useRef } from 'react';
import {
  CollaboratorsSection,
  ExperienceImage,
  ExperinceDrag,
  ExperinceDragCard,
  ExperniceStack,
  Section,
} from '../Themes/StyledComponent';

import Arch1 from '../Assets/Images/Experience/07-sp-egov-removebg-preview.png';
import Arch2 from '../Assets/Images/Arch2.png';
import Arch3 from '../Assets/Images/Arch3.png';
import Arch4 from '../Assets/Images/Arch4.png';
import Arch5 from '../Assets/Images/Arch5.png';
import Arch6 from '../Assets/Images/Experience/02-sp-reap-benefit.png';
// import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ButtonIcon from '../Assets/Images/Experience/Vector (Stroke).png';

import MuseButton from './MuseButton';

const ExperinceDragPage = (props) => {
  const divRef = useRef(null);
  const [cardIndex, setCardIndex] = useState(0);
  const [popup, setPopup] = useState(false);

  const handleTouchStart = (item) => {
    const img = divRef.current?.querySelector('img.imgAdded');
    if (cardIndex === 0) {
      if (item === 'imag_3') {
        if (!img) {
          const img = document.createElement('img');
          img.className = 'imgAdded';
          divRef.current?.appendChild(img);
          img.style.maxWidth = '100%';
          img.setAttribute('src', Arch3);
          if (img) {
            setPopup(true);
          } else {
            setPopup(false);
          }
        }
      }
    }
    if (
      cardIndex === 1 ||
      cardIndex === 2 ||
      cardIndex === 3 ||
      cardIndex === 5
    ) {
      if (item === 'imag_1') {
        if (!img) {
          const img = document.createElement('img');
          img.className = 'imgAdded';
          divRef.current?.appendChild(img);
          img.style.maxWidth = '100%';
          img.setAttribute('src', Arch6);
          if (img) {
            setPopup(true);
          } else {
            setPopup(false);
          }
        }
      }
    }
    if (cardIndex === 4) {
      if (item === 'imag_5') {
        if (!img) {
          const img = document.createElement('img');
          img.className = 'imgAdded';
          divRef.current?.appendChild(img);
          img.style.maxWidth = '100%';
          img.setAttribute('src', Arch5);
          if (img) {
            setPopup(true);
          } else {
            setPopup(false);
          }
        }
      }
    }
    if (cardIndex === 6) {
      if (item === 'imag_6') {
        if (!img) {
          const img = document.createElement('img');
          img.className = 'imgAdded';
          divRef.current?.appendChild(img);
          img.style.maxWidth = '100%';
          img.setAttribute('src', Arch1);
          if (img) {
            setPopup(true);
          } else {
            setPopup(false);
          }
        }
      }
    }
  };

  const nextCardData = (indexVal) => {
    if (indexVal < 6) {
      const val = indexVal + 1;
      setCardIndex(val);
      const img = divRef.current?.querySelector('img.imgAdded');
      img.remove();
      setPopup(false);
    }
  };

  const previousCardData = (indexVal) => {
    const img = divRef.current?.querySelector('img.imgAdded');
    if (indexVal === 0) {
      const val = indexVal;
      setCardIndex(val);
    } else {
      if (img) {
        img.remove();
        setPopup(false);
        const val = indexVal - 1;
        setCardIndex(val);
      } else {
        const val = indexVal - 1;
        setCardIndex(val);
      }
    }
  };
  const handleDragStart = (e, item) => {
    e.dataTransfer.setData('text/plain', item);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const img = divRef.current?.querySelector('img.imgAdded');
    const item = e.dataTransfer.getData('text/plain');
    if (cardIndex === 0) {
      if (item === 'imag_3') {
        if (!img) {
          const img = document.createElement('img');
          img.className = 'imgAdded';
          divRef.current?.appendChild(img);
          img.style.maxWidth = '100%';
          img.setAttribute('src', Arch3);
          if (img) {
            setPopup(true);
          } else {
            setPopup(false);
          }
        }
      }
    }
    if (
      cardIndex === 1 ||
      cardIndex === 2 ||
      cardIndex === 3 ||
      cardIndex === 5
    ) {
      if (item === 'imag_1') {
        if (!img) {
          const img = document.createElement('img');
          img.className = 'imgAdded';
          divRef.current?.appendChild(img);
          img.style.maxWidth = '100%';
          img.setAttribute('src', Arch6);
          if (img) {
            setPopup(true);
          } else {
            setPopup(false);
          }
        }
      }
    }
    if (cardIndex === 4) {
      if (item === 'imag_5') {
        if (!img) {
          const img = document.createElement('img');
          img.className = 'imgAdded';
          divRef.current?.appendChild(img);
          img.style.maxWidth = '100%';
          img.setAttribute('src', Arch5);
          if (img) {
            setPopup(true);
          } else {
            setPopup(false);
          }
        }
      }
    }
    if (cardIndex === 6) {
      if (item === 'imag_6') {
        if (!img) {
          const img = document.createElement('img');
          img.className = 'imgAdded';
          divRef.current?.appendChild(img);
          img.style.maxWidth = '100%';
          img.setAttribute('src', Arch1);
          if (img) {
            setPopup(true);
          } else {
            setPopup(false);
          }
        }
      }
    }
  };

  return (
    <>
      <CollaboratorsSection id={props.id}>
        <Container>
          <Grid
            container
            spacing={{ xs: 2, md: 0 }}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={1}
              lg={1}
              sx={{ textAlign: 'center', zIndex: 99 }}
            >
              <Box
                sx={{
                  border: '2px solid black',
                  borderRadius: '25px',
                  minHeight: '25rem',
                  '@media (max-width:1650px)': {
                    minHeight: '5rem',
                    padding: '0.5rem',
                  },
                }}
              >
                <ExperniceStack
                  // direction="column"
                  direction={{ xs: 'row', md: 'column' }}
                  justifyContent="center"
                  alignItems="center"
                  spacing={{ xs: 2, sm: 4 }}
                  py={3}
                >
                  <img
                    src={Arch2}
                    alt="img"
                    className="dropImg2"
                    draggable="true"
                    width="70%"
                    onTouchStart={(e) => handleTouchStart('imag_2')}
                    onDragStart={(e) => handleDragStart(e, 'imag_2')}
                  />
                  <img
                    className="dropImg1"
                    src={Arch6}
                    alt="img"
                    draggable="true"
                    width="70%"
                    onTouchStart={(e) => handleTouchStart('imag_1')}
                    onDragStart={(e) => handleDragStart(e, 'imag_1')}
                  />

                  <img
                    src={Arch3}
                    alt="img"
                    className="dropImg3"
                    draggable="true"
                    width="70%"
                    onTouchStart={(e) => handleTouchStart('imag_3')}
                    onDragStart={(e) => handleDragStart(e, 'imag_3')}
                  />

                  <img
                    src={Arch5}
                    alt="img"
                    className="dropImg5"
                    draggable="true"
                    width="70%"
                    onTouchStart={(e) => handleTouchStart('imag_5')}
                    onDragStart={(e) => handleDragStart(e, 'imag_5')}
                  />
                  <img
                    src={Arch1}
                    width="70%"
                    alt="img"
                    className="dropImg6"
                    draggable="true"
                    onTouchStart={(e) => handleTouchStart('imag_6')}
                    onDragStart={(e) => handleDragStart(e, 'imag_6')}
                  />
                  <img
                    src={Arch4}
                    alt="img"
                    width="70%"
                    className="dropImg4"
                    draggable="true"
                    onTouchStart={(e) => handleTouchStart('imag_4')}
                    onDragStart={(e) => handleDragStart(e, 'imag_4')}
                  />
                </ExperniceStack>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={10}
              lg={10}
              sx={{ textAlign: 'center', zIndex: 99 }}
            >
              <Grid
                container
                spacing={{ xs: 2, md: 1 }}
                justifyContent="flex-start"
                alignItems="center"
              >
                {' '}
                <Grid
                  item
                  xs={12}
                  sm={7}
                  md={8}
                  lg={8}
                  sx={{ textAlign: 'center', zIndex: 99 }}
                >
                  <ExperinceDragCard>
                    <Box
                      sx={{
                        position: 'relative',
                        display: 'inline-flex',
                        alignItems: 'center',
                      }}
                    >
                      <ExperinceDrag className="mainSrcImg">
                        <img src={props.cardList[cardIndex].image} alt="img" />
                      </ExperinceDrag>
                      <ExperienceImage
                        sx={{
                          top: 0,
                          left: 0,
                          bottom: 0,
                          right: 0,
                          position: 'absolute',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Box
                          ref={divRef}
                          onDragOver={handleDragOver}
                          onDrop={handleDrop}
                          className={props.cardList[cardIndex].imageClass}
                        />
                      </ExperienceImage>
                    </Box>
                  </ExperinceDragCard>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={5}
                  md={4}
                  lg={4}
                  sx={{
                    textAlign: 'left',
                    zIndex: 99,
                    pl: { xs: 0, md: 5 },
                  }}
                >
                  <Box>
                    {popup ? (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Box>
                          <Typography
                            variant="h3"
                            textAlign="Left"

                            // pr={{ xs: 0, md: 9 }}
                          >
                            {props.cardList[cardIndex].title}
                          </Typography>
                          <Typography variant="body" textAlign="Left" mt={2}>
                            {props.cardList[cardIndex].paraData}
                          </Typography>
                          <Box pt={5}>
                            <MuseButton
                              title="Read article"
                              id={props.cardList[cardIndex].id}
                              slug={props.cardList[cardIndex].slug}
                            />
                          </Box>
                        </Box>
                      </Box>
                    ) : null}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Stack
            direction={{ xs: 'row' }}
            justifyContent="center"
            alignItems="center"
            spacing={2}
            py={3}
          >
            <IconButton
              sx={{
                borderRadius: '50%',
                border: '2px solid black',
                width: '50px',
                height: '50px',
                transform: 'rotate(180deg)',
                margin: '20px',
              }}
              disabled={cardIndex === 0}
            >
              <Button size="small" onClick={() => previousCardData(cardIndex)}>
                <img src={ButtonIcon} alt="img" />
              </Button>
            </IconButton>
            <IconButton
              sx={{
                borderRadius: '50%',
                border: '2px solid black',
                width: '50px',
                height: '50px',
              }}
              disabled={cardIndex === props.cardList.length - 1}
            >
              <Button size="small" onClick={() => nextCardData(cardIndex)}>
                <img src={ButtonIcon} alt="img" />
              </Button>
            </IconButton>
          </Stack>
        </Container>
      </CollaboratorsSection>
    </>
  );
};

export default ExperinceDragPage;
