import React, { useEffect, useState } from "react";
import AuthorsLandingPage from "../Components/AuthorsLandingPage";
import AuthorDetails from "../Components/AuthorDetails";
import {
  ArticleDescriptionSection,
  AuthorLanding,
} from "../Themes/StyledComponent";
import { useLocation, useNavigate } from "react-router-dom";
import ScrollDown from "../Components/ScrollDown";
import axios from "axios";

const Authors = () => {
  const [author, setAuthor] = useState([]);
  const url = process.env.REACT_APP_API_URL;
  const [error, setError] = useState("");
  const history = useNavigate();
  const location = useLocation();

  useEffect(() => {
    locationState();
    if (!location.state) {
      onScrollHandler("landingSection");
    }
  }, []);

  useEffect(() => {
    if (location.state) {
      const isObjectPresent = author.find((o) => o.id === location.state);
      if (isObjectPresent) {
        onScrollHandler(location.state);
        // clear the URL state
        history(location.pathname, { replace: true });
      }
    }
  }, [author]);

  const onScrollHandler = (id) => {
    const element = document.getElementById(id);
    if (element) {
      if (id === "landingSection") {
        element?.scrollIntoView({
          block: "nearest",
          inline: "start",
        });
      } else if (id === location.state) {
        element?.scrollIntoView({
          block: "center",
          inline: "start",
        });
      } else {
        element?.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "start",
        });
      }
    }
  };

  const locationState = async () => {
    try {
      await axios
        .get(`${url}wp/v2/users`)
        .then((response) => {
          let articles = response.data;
          let cards = [];

          for (let index = 0; index < articles.length; index++) {
            const element = articles[index];
            let card = {
              id: element.id,
              slug: element.slug,
              img: element.acf.bio_pic,
              author: element.name,
              designation: element.acf.company_name,
              blurb: element.description,
              linked: element.acf?.linked_in,
              website: element.acf?.website,
            };
            cards = [...cards, card];
          }
          let articlesOrderBySlug = process.env.REACT_APP_USER_SORT;
         

          const sortedArticles = cards.sort((cardA, cardB) => {
            const indexA = articlesOrderBySlug.split(",").indexOf(cardA.author);
            const indexB = articlesOrderBySlug.split(",").indexOf(cardB.author);
            return indexA - indexB;
          });
          setAuthor(sortedArticles);
        })
        .catch((error) => {
          console.log(error);
          setError(error);
        });
    } catch (e) {
      setError(e);
    }
  };

  return (
    <>
      <AuthorLanding pb={5}>
        <AuthorsLandingPage id="landingSection" />
        <ScrollDown
          scrollFunction={() => onScrollHandler("authorDetails")}
          imageSrc=""
        />
      </AuthorLanding>

      <ArticleDescriptionSection>
        {author && <AuthorDetails id="authorDetails" author={author} />}
      </ArticleDescriptionSection>
    </>
  );
};

export default Authors;
