import React, { useState, useEffect } from "react";
import ArticleLandingPage from "../Components/ArticleLandingPage";

import AllArticlesCard from "../Components/AllArticlesCard";
import { ArticleLanding, DescriptionSection } from "../Themes/StyledComponent";
import axios from "axios";
import ScrollDown from "../Components/ScrollDown";

const AllArticles = () => {
  const [itemData, setItemData] = useState([]);
  const url = process.env.REACT_APP_API_URL;
  const [error, setError] = useState("");
  useEffect(() => {
    onScrollHandler("landingSection");
    locationState();
  }, []);
  const locationState = async () => {
    try {
      await axios.get(`${url}wp/v2/posts?&_embed`).then((response) => {
        let articles = response.data;
        console.log(articles, "articles");
        let cards = [];
        for (let index = 0; index < articles.length; index++) {
          const element = articles[index];
          const parser = new DOMParser();
          const contentForTitle = parser.parseFromString(
            element.title.rendered,
            "text/html"
          );
          const title = contentForTitle.body.innerText;
          const excerptContent = parser.parseFromString(
            element.excerpt.rendered,
            "text/html"
          );
          const excerpt = excerptContent.body.innerText.replace("\n", "");
          let fImage = element._embedded["wp:featuredmedia"]
            ? element._embedded["wp:featuredmedia"]["0"].source_url
            : "";
          let card = {
            title,
            excerpt,
            image: fImage,
            author: element._embedded["author"][0].name,
            authorID: element._embedded["author"][0].id,
            slug: element.slug,
          };
          cards = [...cards, card];
        }
        let articlesOrderBySlug = process.env.REACT_APP_SORT;

        const sortedArticles = cards.sort((cardA, cardB) => {
          const indexA = articlesOrderBySlug.split(",").indexOf(cardA.author);
          const indexB = articlesOrderBySlug.split(",").indexOf(cardB.author);
          return indexA - indexB;
        });

        setItemData(sortedArticles);
      });
    } catch (e) {
      setError(e);
    }
  };

  const onScrollHandler = (id) => {
    const element = document.getElementById(id);
    if (id === "landingSection") {
      element?.scrollIntoView({
        block: "nearest",
        inline: "start",
      });
    } else {
      element?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }
  };

  return (
    <>
      <ArticleLanding pb={5}>
        <ArticleLandingPage
          id="landingSection"

        />
        <ScrollDown
          scrollFunction={() => onScrollHandler("article_cards")}
          imageSrc="drak"
        />
      </ArticleLanding>

      <DescriptionSection py={{ xs: 1, sm: 3, md: 5 }}>
        <AllArticlesCard id={"article_cards"} itemData={itemData} />
      </DescriptionSection>
    </>
  );
};

export default AllArticles;
