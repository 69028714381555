import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";

import Pen from "../Assets/Images/pen.png";
import Divider from "../Assets/Images/Vector 42.png";

import StoryCard from "./StoryCard";

const styles = {
  LandingBackground: {
    backgroundImage: `linear-gradient(to bottom, #ECE4EF, #ECE4EF)`,
  },
};
let itemData;
const StoriesAritcle = (props) => {
 
  if (props.showCards) {
    itemData = props.showCards;
  }
 
  return (
    <>
      <Box sx={{ textAlign: "center" }} py={6} style={styles.LandingBackground}>
        <Container textAlign="center">
          <Typography variant="h2" textAlign="center">
            Our{" "}
            <span
              style={{
                background: ` url(${Divider}) bottom no-repeat`,
                paddingBottom: "7px",
                backgroundSize: "contain",
              }}
            >
              Stories
            </span>
            <img src={Pen} alt="img" />
          </Typography>

          <Grid
            container
            spacing={0}
            justifyContent="space-evenly"
            alignItems="center"
          >
            {itemData.map((item, index) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  lg={4}

              
                >
                  <StoryCard
                    title={item.title}
                    blurb={item.excerpt}
                    images={item.image}
                    Author={item.author}
                    id={item.authorID}
                    slug={item.slug}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default StoriesAritcle;
