import {
  Box,
  Container,
  Grid,
  Typography,
  Card,
  CardMedia,
  CardContent,
} from '@mui/material';
import React from 'react';

import Expernice_intro1 from '../Assets/Images/Experience/How to play/sp-muse-howtoplay-01.png';
import Expernice_intro2 from '../Assets/Images/Experience/How to play/sp-muse-howtoplay-02.png';
import Expernice_intro3 from '../Assets/Images/Experience/How to play/sp-muse-howtoplay-03.png';
import Divider from '../Assets/Images/scrible/how-to-play.png';

import { Section } from '../Themes/StyledComponent';

const ExperinceIntro = (props) => {
  return (
    <>
      <Section id={props.id}>
        <Container sx={{ position: 'relative' }}>
          <Box pt={0}>
            <Typography variant="h1" textAlign="center">
              <b>
                {' '}
                How to{' '}
                <span
                  style={{
                    background: `url(${Divider})bottom no-repeat `,
                    paddingBottom: '6px',
                    backgroundSize: 'contain',
                  }}
                >
                  play
                </span>
              </b>
            </Typography>
          </Box>
          <Grid
            container
            spacing={{ xs: 2 }}
            justifyContent={{xs:"center",md:"space-evenly"}}
            alignItems="center"
            py={{ xs: 5, sm: 6 }}
            px={{ xs: 0, sm: 0 }}
          >
            <Grid item xs={12} sm={6} md={4} lg={3} spacing={0}>
              <Card classes="experienceCard">
                <CardMedia sx={{ height: '190px' }} image={Expernice_intro1} />
                <CardContent>
                  <Typography gutterBottom variant="h5">
                    01
                  </Typography>
                  <Typography variant="h4" display={{xs:'none',md:"block"}}>
                    Select the arch from the right panel
                    
                  </Typography>
                  <Typography variant="h4" display={{xs:'block',md:"none"}}>
                    
                    Tap on the right arch and see the exponential change appear.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
              
            <Grid item xs={12} sm={6} md={4} lg={3} spacing={0} display={{ xs: 'none', md: 'block' }}>
            
              <Card classes="experienceCard">
                <CardMedia sx={{ height: '190px' }} image={Expernice_intro2} />
                <CardContent>
                  <Typography gutterBottom variant="h5">
                    02
                  </Typography>
                  <Typography variant="h4">
                    Drag and drop the arch onto the illustration
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              spacing={0}
              display={{ xs: 'none', md: 'block' }}
            >
              <Box display={{ xs: 'none', md: 'block' }}>
                <Card classes="experienceCard">
                  <CardMedia
                    sx={{ height: '190px' }}
                    image={Expernice_intro3}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5">
                      03
                    </Typography>
                    <Typography variant="h4">
                      Adjust the arch to fit in the illustration
                    </Typography>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Section>
    </>
  );
};

export default ExperinceIntro;
