import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { Section } from '../Themes/StyledComponent';
import { StyledContainer } from '../Themes/StyledComponent';
import MuseButton from './MuseButton';
// import { Link } from 'react-router-dom';

const Blub2 = (props) => {
  return (
    <>
      <Section id={props.id}>
        <StyledContainer sx={{ position: 'relative' }}>
          <Grid
            container
            spacing={0}
            justifyContent="start"
            alignItems="start"
            pb={{ xs: 2, md: 8 }}
          >
            <Grid item xs={12} sm={12} md={8} lg={8} textAlign="left">
              <Typography variant="h3" textAlign="Left" pr={{ xs: 0, md: 9 }}>
                What if you could apply Societal Thinking to problems and
                understand the difference you could make?
              </Typography>
              <Typography variant="h3" textAlign="Left" mt={2}>
                <b>Play along</b> by bringing forth
                <br /> your
                <Typography variant="link">
                  <b> childlike curiosity.</b>
                </Typography>
              </Typography>
              <Box py={5}>
                <MuseButton title="Experience" routePathTo={'routePathTo'} />
              </Box>
            </Grid>
          </Grid>
        </StyledContainer>
      </Section>
    </>
  );
};

export default Blub2;
