import  { useState } from "react";


const useMuseButton = () => {
  const [museButton, setMuseButton] = useState(false);
  const onMouseEnter = (isOpen) => {
    setMuseButton(true);
  };

  const onMouseLeave = (isOpen) => {
    setMuseButton(false);
  };

  return {
    museButton,
    setMuseButton,
    onMouseEnter,
    onMouseLeave
  };
};

export default useMuseButton;
