import React, { useEffect } from 'react';
import ExpericeLandingPage from '../Components/ExpericeLandingPage';
import ExperinceIntro from '../Components/ExperinceIntro';
import ExperinceDragPage from '../Components/ExperinceDragPage';
import {
  ArticleDescriptionSection,
  ExpernceLanding,
} from '../Themes/StyledComponent';
import Article1 from '../Assets/Images/Experience/sp-muse-harambee-dotted.jpg';
import Article2 from '../Assets/Images/Experience/sp-muse-reap-benefit-dotted.jpg';
import Article3 from '../Assets/Images/Experience/sp-muse-citymart-dotted.jpg';
import Article4 from '../Assets/Images/Experience/sp-muse-shikshalokam-dotted.jpg';
import Article5 from '../Assets/Images/Experience/sp-muse-seeds-dotted.jpg';
import Article6 from '../Assets/Images/Experience/sp-muse-echo-dotted.jpg';
import Article7 from '../Assets/Images/Experience/sp-muse-egov-dotted.jpg';
import ScrollDown from '../Components/ScrollDown';

const Experince = () => {
  const onScrollHandler = (id) => {
    const element = document.getElementById(id);
    if (id === 'landingSection') {
      element?.scrollIntoView({
        block: 'nearest',
        inline: 'start',
      });
    } else {
      element?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      });
    }
  };
  const cardList = [
    {
      id: 14,
      slug: 'how-can-every-young-person-reach-their-potential-by-having-access-to-opportunities',
      icon: '1',
      title: 'When society, state and markets work together',
      paraData: 'How can every young person  have access to  opportunities?',
      image: Article1,
      imageClass: 'harambeeDotted',
    },
    {
      id: 3,
      icon: '1',
      slug: 'can-anyone-who-keeps-the-street-clean-become-a-hero',
      title: 'Solve small,dent big',
      paraData: 'Can anyone who keeps the street  clean  become a hero?',
      image: Article2,
      imageClass: 'benifitDotted',
    },
    {
      id: 15,
      slug: 'how-can-governments-and-social-innovators-come-together-to-create-impact',
      icon: '3',
      title: 'Striking collaboration gold',
      paraData:
        'How can governments and social innovators come together to create impact?',
      image: Article3,
      imageClass: 'citymartDotted',
    },
    {
      id: 17,
      slug: 'how-can-education-leaders-improve-their-schools-consistently',
      icon: '4',
      title: 'Don’t build again, build beyond',
      paraData:
        'How can education leaders improve their schools,  consistently?',
      image: Article4,
      imageClass: 'shikshalokamDotted',
    },
    {
      id: 4,
      slug: 'how-can-we-all-thrive-in-a-rapidly-changing-planet',
      icon: '5',
      title: 'Reimagining disaster response',
      paraData: 'How can we all thrive  in a rapidly changing planet?',
      image: Article5,
      imageClass: 'seedsDotted',
    },
    {
      id: 5,
      slug: 'can-right-knowledge-at-the-right-place-at-the-right-time-save-millions-of-lives',
      icon: '6',
      title: 'All teach, all learn',
      paraData:
        'Can right knowledge  at the right place,  at the right time  save millions of  lives?',
      image: Article6,
      imageClass: 'echoDotted',
    },
    {
      id: 21,
      slug: 'how-can-we-make-governance-and-citizen-services-transparent-and-easily-accessible',
      icon: '7',
      title: 'The power of public goods',
      paraData:
        'How can we make  citizen services transparent and easily  accessible?',
      image: Article7,
      imageClass: 'egovDotted',
    },
  ];

  useEffect(() => {
    onScrollHandler('landingSection');
  }, []);
  return (
    <>
      <ExpernceLanding pb={5}>
        <ExpericeLandingPage id="landingSection" />
        <ScrollDown
          scrollFunction={() => onScrollHandler('experienceIntro')}
          imageSrc="drak"
        />
      </ExpernceLanding>

      <ArticleDescriptionSection
        py={{ xs: 1, sm: 3, md: 5 }}
        sx={{ borderRadius: '30px 30px 30px 30px' }}
      >
        <ExperinceIntro id="experienceIntro" />
        <ScrollDown
          scrollFunction={() => onScrollHandler('experienceDrag')}
          imageSrc="drak"
        />
        <ExperinceDragPage id="experienceDrag" cardList={cardList} />
      </ArticleDescriptionSection>
    </>
  );
};

export default Experince;
