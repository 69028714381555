import { Box, SpeedDial, SpeedDialAction } from '@mui/material';
import React, { useState } from 'react';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import ReactGA from 'react-ga4';
import linkedInImage from '../Assets/Images/Icons/Purple BG Icons/icon-linkedin-collabs-1.png';
import twitterImage from '../Assets/Images/Icons/Purple BG Icons/icon-twitter-collabs-1.png';
import copyUrlImage from '../Assets/Images/Icons/Purple BG Icons/icon-copy-text-2.png';
import { ShareBox } from '../Themes/StyledComponent';
import facebookImage from '../Assets/Images/Icons/Purple BG Icons/icon-fb-collabs-1.png';
import ShareIcon from '../Assets/Images/Icons/Purple BG Icons/icon-share-1.png';
const ShareIcons = ({ slug, id, direction, classes }) => {
  const shareUrl = `${window.location.protocol}//${window.location.host}/#/article?post=${id}&slug=${slug}`;
  const cardUrl = `${window.location.protocol}//${window.location.host}/#/article?post=${id}&slug=${slug}`;
  const [iconData, setIconData] = useState(false);
  const shareClick = (category, action, label) => {
    ReactGA.event({
      category, 
      action, 
      label 
     });
  };
  
  const actions = [
    {
      icon: (
        <LinkedinShareButton url={shareUrl} onClick={shareClick('Share','Linkedin',shareUrl)}>
          <img
            src={linkedInImage}
            style={{ height: '100%', width: '100%' }}
            alt="img"
          />{' '}
        </LinkedinShareButton>
      ),
      name: 'LinkedIn',
    },
    {
      icon: (
        <FacebookShareButton url={shareUrl} onClick={shareClick('Share','Facebook',shareUrl)}>
          <img
            src={facebookImage}
            style={{ height: '100%', width: '100%' }}
            alt="img"
          />
        </FacebookShareButton>
      ),
      name: 'Facebook',
    },
    {
      icon: (
        <TwitterShareButton url={shareUrl} onClick={shareClick('Share','Twitter',shareUrl)}>
          <img
            src={twitterImage}
            url={shareUrl}
            style={{ height: '100%', width: '100%' }}
            alt="img"
          />
        </TwitterShareButton>
      ),
      name: 'Twitter',
    },
{
    icon: (
      <img
        src={copyUrlImage}
        style={{ height: '100%', width: '100%' }}
        alt="Copy URL"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation(); 
          copyToClipboard(cardUrl); 
        }}
      />
    ),
    name: iconData ? 'Copied' : 'Copy URL',
  },
  ];

  const copyToClipboard = (text) => {
    const textToCopy = `Read this story of exponential societal change on ${text}`;
    navigator.clipboard.writeText(textToCopy).then(
      (res) => {
        shareClick('Share','Copied to clipboard',text)
        setIconData(true);
      },
      (err) => {
        setIconData(false);
      }
    );
  };
  return (
    <>
      <Box
        sx={{
          transform: 'translateZ(0px)',
          flexGrow: 1,
          postion: 'relative',
        }}
      >
        <ShareBox
          sx={{
            transform: 'translateZ(0px)',
          }}
        >
          <SpeedDial
            ariaLabel="SpeedDial basic example"
            className={classes}
            sx={{
              position: 'absolute',
              bottom: '7.8rem',
              right: '0rem',
              '& .MuiFab-primary': {
                alignContent: 'center',
                boxShadow: 'none',
                border: '1px solid black',
                height: '2.5rem',
                width: '2.5rem',
                textTransform: 'capitalize',
                display: 'flex',
                alignItems: 'center',
              },
            }}
            direction={direction}
            icon={
              <img
                style={{ height: '3.4rem', width: '3.4rem' }}
                src={ShareIcon}
                alt="img"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              />
            }
          >
            {actions.map((action, index) => (
              <SpeedDialAction
                sx={{
                  display: 'flex',
                  alignContent: 'center',
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  copyToClipboard(cardUrl);
                }}
                key={index}
                icon={action.icon}
                tooltipTitle={action.name}
              />
            ))}
          </SpeedDial>
        </ShareBox>
      </Box>
    </>
  );
};

export default ShareIcons;
