import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Divider,
} from "@mui/material";
import { useLocation } from "react-router";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import ShareIcons from "./ShareIcons";
import ReactGA from "react-ga";
import { Link } from "react-router-dom";

const useAnalyticsEventTracker = (category = "share") => {
  const eventTracker = (action = "download", label = "pdf") => {
    ReactGA.event({ category, action, label });
  };
  return eventTracker;
};

const StoryCard = ({
  value,
  title,
  blurb,
  Author,
  images,
  id,
  slug,
  image_gif,
  pdf,
}) => {
  const location = useLocation();
  const path = location.pathname;
  const gaEventTracker = useAnalyticsEventTracker("share");
  const shareClick = (gifUrl, pdfUrl, authorTitle) => {
    downloadPdfDocument(gifUrl, pdfUrl, authorTitle);
    gaEventTracker(pdfUrl);
  };
  const downloadPdfDocument = async (gifUrl, pdfUrl, authorTitle) => {
    try {
      const pdfResponse = await fetch(pdfUrl);  
      const pdfBlob = await pdfResponse.blob();
      const pdfBlobUrl = URL.createObjectURL(pdfBlob);
      const pdfLink = document.createElement("a");
      pdfLink.href = pdfBlobUrl;
      pdfLink.download = authorTitle;
      document.body.appendChild(pdfLink);
      pdfLink.click();
      document.body.removeChild(pdfLink);
      URL.revokeObjectURL(pdfBlobUrl);
      // if (gifUrl !== false) {
      //   const gifResponse = await fetch(gifUrl);
      //   const gifBlob = await gifResponse.blob();
      //   const gifBlobUrl = URL.createObjectURL(gifBlob);
      //   const gifLink = document.createElement('a');
      //   gifLink.href = gifBlobUrl;
      //   gifLink.download = authorTitle;
      //   document.body.appendChild(gifLink);
      //   gifLink.click();
      //   document.body.removeChild(gifLink);
      //   URL.revokeObjectURL(gifBlobUrl);
      // }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
  <Card
  sx={{
    m: 2,
    p: 0,
    borderRadius: "25px",
    minHeight: { xs: "20rem", md: "30rem" },  // Set a consistent minHeight
    display: "flex",  // Flexbox layout
    flexDirection: "column",
    justifyContent:"flex-end"
  }}
  key={value}
  style={{ cursor: "pointer" }}
>
  <Link to={`/article?post=${id}&slug=${slug}`} className="Link" style={{ display: "flex", flexDirection: "column", height: "100%" }}>
    {/* CardMedia */}
    <CardMedia
      component="img"
      height="310rem"  // Set a fixed height for the image
      image={images}
      alt="green iguana"
      style={{ flexShrink: 0 }}  // Prevents image from shrinking
    />
    
    <Divider light variant="inset" />

    {/* CardContent */}
    <CardContent
  sx={{
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between", // Spreads the content evenly
    minHeight: { xs: "12rem", md: "13rem" }, // Ensure consistent height
  }}
>
  <Box minHeight={path !== "/share" ? "6rem" : "4rem"}>
    <Typography variant="subtitle2" sx={{ lineHeight: "1.5rem" }}>
      <div>{title}</div>
    </Typography>
    {path !== "/share" && (
      <Typography
        gutterBottom
        variant="body2"
        component="div"
        sx={{ minHeight: "2.5rem" }}
        pt={1}
      >
        <div>{blurb}</div>
      </Typography>
    )}
  </Box>

  <Box mt="auto"> {/* Pushes the author and share icons to the bottom */}
    <Typography
      gutterBottom
      variant="body1"
      component="div"
      pt={1}
      textTransform="uppercase"
      pb={1}
    >
      {Author}
    </Typography>
    {path === "/share" && (
      <Button
        onClick={() => shareClick(image_gif, pdf, title)}
        variant="outlined"
        endIcon={<SaveAltIcon />}
        size="large"
        sx={{
          borderRadius: "25px",
          color: "#000",
          border: "1px solid black",
          textAlign: "left",
          mr: 5,
        }}
      >
        Download
      </Button>
    )}
    <ShareIcons slug={slug} direction="up" id={id} classes="share" />
  </Box>
</CardContent>

  </Link>
</Card>

    </>
  );
};

export default StoryCard;
