import { Container, Grid, Typography } from '@mui/material';
import React from 'react';
import { LandingImage, Section } from '../Themes/StyledComponent';
import Divider from '../Assets/Images/Vector 6.png';
import Art from '../Assets/Images/Home/sp-muse-books-black.png';

const ExpericeLandingPage = (props) => {
  return (
    <>
      <Section id={props.id} pt={{ xs: 4, sm: 14, md: 13 }}>
        <Container sx={{ position: 'relative' }}>
          <Grid
            container
            spacing={0}
            justifyContent="start"
            alignItems="start"
            direction={{ xs: 'column-reverse', sm: 'row' }}
            pb={3}
          >
            <Grid item xs={12} sm={12} md={7} lg={7} sx={{ textAlign: 'left' }}>
              <LandingImage display={{ xs: 'block', sm: 'none' }}>
                <img src={Art} width="200%" alt="img" />
              </LandingImage>
              <Typography variant="h1" textAlign="Left" pb={{ xs: 0, sm: 3 }}>
                <b>
                  {' '}
                  Usher change{' '}
                  <span
                    style={{
                      background: ` url(${Divider}) bottom no-repeat`,
                      paddingBottom: '05px',
                      backgroundSize: 'contain',
                    }}
                  >
                    for good <br />
                  </span>{' '}
                  by turning the page.
                </b>
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={0}
            justifyContent="start"
            alignItems="start"
            direction={{ sm: 'row' }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              sx={{ textAlign: 'left' }}
              pr={5}
            >
              <Typography
                variant="body"
                py={{ xs: 2, sm: 2, md: 18 }}
                pb={3}
                pr={4}
              >
                Let’s live these stories of change with our storytellers. In
                every story, the Societal Thinking arch brings out the
                ‘exponential’. First, let’s see the illustration by itself.
                Then, with the arch. What changes?
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              sx={{ textAlign: 'left' }}
              mt={{ xs: 0, md: -25 }}
            >
              <LandingImage display={{ xs: 'none', sm: 'block' }}>
                <img src={Art} width="200%" alt="img" />
              </LandingImage>
            </Grid>
          </Grid>
        </Container>
      </Section>
    </>
  );
};

export default ExpericeLandingPage;
