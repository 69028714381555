/**
 * Typography used in theme
 * @param {JsonObject} theme theme customization object
 */

import { createTheme } from "@mui/material/styles";

import PALETTE from "./Palette";
import FONT from "./Font";

import Line from "../Assets/Images/Vector 6.png";
import UnderLine from "../Assets/Images/Vector 12.png";
import ArrowImage from "../Assets/Images/RightArrow.png";

// import ArrowImage from '../Assets/Images/Home/SP_arrowIcon.png';
// import Campgain from '../Assets/fonts/CampaignMedium.woff';
import Campgain from "../Assets/fonts/07fd4d9a-95e6-4f64-a2e2-56c638c256ca.woff";
import Montserrat_Regular from "../Assets/fonts/Montserrat-Regular.ttf";

// import Campgain from '../Assets/fonts/Campaign-Normal.ttf';

const theme = createTheme({
  typography: {
    fontFamily: FONT.FAMILY.ASSISTANT,
    h1: {
      fontFamily: FONT.FAMILY.CAMPAIGN,
      // lineHeight:'16px',
      // fontWeight: FONT.WEIGHT.BOLD,
      fontSize: FONT.SIZES[48],
      color: PALETTE.BLACK_SHADE_1,
      "@media (min-width: 551px) and (max-width:1400px)": {
        fontSize: FONT.SIZES[38],
      },
      "@media (min-width: 320px) and (max-width:550px)": {
        fontSize: FONT.SIZES[24],
      },
    },
    h2: {
      fontFamily: FONT.FAMILY.CAMPAIGN,
      fontWeight: FONT.WEIGHT.MEDIUM,
      fontSize: FONT.SIZES[40],
      color: PALETTE.BLACK,
      marginBottom: "30px",
      "@media (min-width: 551px) and (max-width:1400px)": {
        fontSize: FONT.SIZES[30],
      },
      "@media (max-width:550px)": {
        fontSize: FONT.SIZES[24],
        lineHeight: "25.2px",
      },
    },
    h3: {
      fontFamily: FONT.FAMILY.CAMPAIGN,
      fontWeight: FONT.WEIGHT.MEDIUM,
      fontSize: FONT.SIZES[32],
      color: PALETTE.BLACK,
      // textTransform: 'capitalize',
      lineHeight: "3rem",
      "@media (min-width: 551px) and (max-width:1400px)": {
        fontSize: FONT.SIZES[24],
        lineHeight: "31px",
      },
      "@media (max-width:550px)": {
        fontSize: FONT.SIZES[18],
        lineHeight: "25.2px",
      },
    },
    h4: {
      fontWeight: FONT.WEIGHT.REGULAR,
      fontFamily: FONT.FAMILY.CAMPAIGN,
      fontSize: FONT.SIZES[24],
      color: "#241C15",
      // letterSpacing: "1px",
      "@media (min-width: 551px) and (max-width:1400px)": {
        fontSize: FONT.SIZES[20],
        lineHeight: 1.5,
      },
      "@media (max-width:550px)": {
        fontSize: FONT.SIZES[18],
        lineHeight: 1.3,
        // lineHeight: "25.2px",
      },
    },
    h5: {
      fontFamily: FONT.FAMILY.ASSISTANT,
      fontWeight: FONT.WEIGHT.REGULAR,
      fontSize: FONT.SIZES[18],
      color: PALETTE.BLACK,
      "@media (min-width: 551px) and (max-width:1400px)": {
        fontSize: FONT.SIZES[15],
      },
      "@media (max-width:550px)": {
        fontSize: FONT.SIZES[15],
        lineHeight: "18px",
      },
    },
    h6: {
      fontWeight: FONT.WEIGHT.MEDIUM,
      fontSize: FONT.SIZES[16],
      fontFamily: FONT.FAMILY.ASSISTANT,
      // marginBottom: '10px',
      "@media (min-width: 551px) and (max-width:1400px)": {
        fontSize: FONT.SIZES[14],
      },
    },
    body: {
      fontWeight: FONT.WEIGHT.MEDIUM,
      fontSize: FONT.SIZES[20],
      fontFamily: FONT.FAMILY.ASSISTANT,
      lineHeight: "27px",
      // letterSpacing: "1px",

      "@media (max-width:1400px)": {
        fontSize: FONT.SIZES[15],
        lineHeight: "18px",
      },
    },

    body1: {
      // fontWeight: FONT.WEIGHT.DEMI_BOLD,
      fontSize: FONT.SIZES[14],
      fontFamily: FONT.FAMILY.ASSISTANT,
      fontWeight: FONT.WEIGHT.MEDIUM,
      lineHeight: "20px !important",
      color: "#000",
      paddingBottom: "0.2rem",
      //  fontWeight: '600',
      // color: "rgb(0, 0, 0, 50%)",

      "@media (min-width: 551px) and (max-width:1400px)": {
        fontSize: FONT.SIZES[12],
        lineHeight: "14px",
      },
      "@media (max-width:550px)": {
        fontSize: FONT.SIZES[15],
        lineHeight: "18px",
      },
    },
    body2: {
      fontWeight: FONT.WEIGHT.MEDIUM,
      fontSize: FONT.SIZES[15],
      fontFamily: FONT.FAMILY.ASSISTANT,
      color: "#000",
      fontWeight: 600,
      color: "rgb(0, 0, 0, 50%)",
      "@media (max-width:1400px)": {
        fontSize: FONT.SIZES[15],
        // lineHeight: '14px',
      },
    },
    link: {
      background: ` url(${Line}) bottom no-repeat`,
      fontSize: FONT.SIZES[32],
      fontFamily: FONT.FAMILY.CAMPAIGN,
      lineHeight: "48px",
      paddingBottom: "5px",
      "@media (min-width: 551px) and (max-width:1400px)": {
        fontSize: FONT.SIZES[24],
      },
      "@media (max-width:550px)": {
        fontSize: FONT.SIZES[18],
        background: ` url(${UnderLine}) bottom no-repeat,`,
        backgroundSize: "contain",

        lineHeight: "25.2px",
      },
    },
    subtitle1: {
      "&:hover": {
        background: ` url(${Line}) bottom no-repeat,`,
        cursor: "pointer",
      },
      fontWeight: FONT.WEIGHT.REGULAR,
      fontFamily: FONT.FAMILY.CAMPAIGN,
      fontSize: FONT.SIZES[24],
      "@media (min-width: 551px) and (max-width:1400px)": {
        fontSize: FONT.SIZES[18],
      },
      "@media (max-width:550px)": {
        fontSize: FONT.SIZES[18],
        background: ` url(${UnderLine}) bottom no-repeat,`,
        backgroundSize: "contain",
        lineHeight: "25.2px",
      },
    },
    caption: {
      "&.ActiveClass": {
        background: ` url(${ArrowImage}) bottom right no-repeat`,
        opacity: 1,
        cursor: "pointer",
        backgroundSize: "10%",

        // "&:hover": {
        "& .link": {
          background: ` url(${Line}) bottom no-repeat`,
          opacity: 1,
          cursor: "pointer",
          paddingBottom: "10px",
        },
        // },
      },

      fontWeight: FONT.WEIGHT.REGULAR,
      fontFamily: FONT.FAMILY.CAMPAIGN,
      fontSize: FONT.SIZES[24],
      opacity: 0.2,
      "@media (max-width:1400px)": {
        // '&.ActiveClass': {
        //   background: ` none`,
        //   opacity: 1,
        //   cursor: 'pointer',
        // },
        opacity: 1,
        fontSize: FONT.SIZES[20],
      },
      "@media (max-width:1024px)": {
        "&.ActiveClass": {
          background: ` none`,
          opacity: 1,
          cursor: "pointer",
          "& .link": {
            background: `none`,
          },
        },
        opacity: 1,
        fontSize: FONT.SIZES[18],
        lineHeight: "25.2px",
      },
    },
    subtitle2: {
      fontFamily: FONT.FAMILY.CAMPAIGN,
      fontWeight: FONT.WEIGHT.REGULAR,

      fontSize: FONT.SIZES[20],
      color: "#241C15",
      "@media (min-width: 551px) and (max-width:1400px)": {
        fontSize: FONT.SIZES[20],
      },
      "@media (max-width:550px)": {
        fontSize: FONT.SIZES[20],
        // lineHeight: "25.2px",
      },
    },
    overline: {
      // '&:hover': {
      //   background: ` url(${ArrowImage}) bottom right no-repeat`,
      //   backgroundSize: "contain",
      // },
      fontWeight: FONT.WEIGHT.SEMI_BOLD,
      fontFamily: FONT.FAMILY.ASSISTANT,
      fontSize: FONT.SIZES[16],
      letterSpacing: "0.1rem",
      "@media (max-width:1400px)": {
        fontSize: FONT.SIZES[14],
        letterSpacing: "0rem",
      },
    },
  },
  palette: {
    primary: {
      main: "#fdfdfd",
      contrastText: "#000",
    },
    secondary: {
      main: "#000",
    },
    success: {
      main: "#935fb0",
    },
    action: {
      main: "#E5E5E5",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "@font-face": {
          fontFamily: "Campaign",
          src: `url(${Campgain}) format("truetype")`,
        },
        body: {
          color: "#241C15",
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: "contained" },
          style: {
            "&:hover": {
              boxShadow:
                "3px 6px 0px -4px rgb(24 12 12 / 99%), 3px 2px 2px 0px rgb(0 0 0 / 98%), 3px 3px 0px 0px rgb(0 0 0 / 95%)",
            },
            border: "1px solid black",
            fontFamily: FONT.FAMILY.INTER,
            fontSize: FONT.SIZES[12],
            fontWeight: FONT.WEIGHT.SEMI_BOLD,
            borderRadius: "25px",
          },
        },
        {
          props: { variant: "Menu-btn" },
          style: {
            fontFamily: FONT.FAMILY.CAMPAIGN,
            fontWeight: FONT.WEIGHT.REGULAR,
            fontSize: FONT.SIZES[18],
            color: " #241c15",
            // border: "1px solid black",
            borderRadius: "50px 0px 0px 50px",
            textAlign: "center",
          },
        },
        {
          props: { variant: "audio-btn" },
          style: {
            fontFamily: FONT.FAMILY.ASSISTANT,
            fontWeight: 600,
            fontSize: FONT.SIZES[16],
            width: "9.25rem",
            height: "3rem",
            border: "1.5px solid #241C15",
            borderRadius: "100px",
          },
        },

        {
          props: { variant: "outlined" },
          style: {
            border: "1px solid black",
            fontFamily: FONT.FAMILY.INTER,
            fontSize: FONT.SIZES[12],
            fontWeight: FONT.WEIGHT.SEMI_BOLD,
            borderRadius: "25px",
            color: "#000",
            "&:hover": {
              // boxShadow:
              //   '3px 6px 0px -4px rgb(24 12 12 / 99%), 3px 2px 2px 0px rgb(0 0 0 / 98%), 3px 3px 0px 0px rgb(0 0 0 / 95%)',
              border: "1px solid black",
            },
          },
        },
      ],
    },

    MuiAppBar: {
      styleOverrides: {
        // props:{classes:"AppBorder"},
        root: {
          // borderBottom: '1px solid black',
          paddingTop: "1rem",
          paddingBottom: "0.15rem",
          "@media (max-width:1400px)": {
            paddingTop: "0.5rem",
            paddingBottom: "0.5rem",
            minHeight: "35px",
          },
        },
      },
    },
    MuiCard: {
      variants: [
        {
          props: { classes: "values1" },
          style: {
            borderRadius: "120px 120px 20px 20px",
            /*  transform: "rotate(-4deg)", */

            "@media (max-width:550px)": {
              /* transform: "rotate(-4deg)", */
            },
          },
        },
        {
          props: { classes: "values1_flip" },
          style: {
            borderRadius: "120px 120px 20px 20px",
            /*  transform: "rotate(-4deg)", */
          },
        },
        {
          props: { classes: "values2" },
          style: {
            borderRadius: "20px 20px 120px 120px",
            cursor: "pointer",
            /* transform: "rotate(7deg)", */

            "@media (max-width:550px)": {
              /* transform: "rotate(-3deg)", */
            },
          },
        },
        {
          props: { classes: "values2_flip" },
          style: {
            borderRadius: "20px 20px 120px 120px",
            cursor: "pointer",
            /* transform: "rotate(7deg)", */

            "@media (max-width:550px)": {
              /* transform: "rotate(3deg)", */
            },
          },
        },
        {
          props: { classes: "values3" },
          style: {
            borderRadius: "120px 120px 20px 20px",
            //transform: "rotate(4deg)",

            "@media (max-width:550px)": {
              /*  transform: "rotate(3deg)", */
            },
          },
        },
        {
          props: { classes: "values3_flip" },
          style: {
            borderRadius: "120px 120px 20px 20px",
            // transform: "rotate(4deg)",
            /* p: { transform: "rotate(0deg)" }, */
            "@media (max-width:550px)": {
              /*  transform: "rotate(-3deg)", */
              /* p: { transform: "rotate(0deg)" }, */
            },
          },
        },
        {
          props: { classes: "values4" },
          style: {
            borderRadius: "20px 20px 120px 120px",
            /*  transform: "rotate(-6deg)", */
            /*  p: { transform: "rotate(-6deg)" }, */
            /* p: { transform: "rotate(0deg)" }, */
          },
        },
        {
          props: { classes: "values4_flip" },
          style: {
            borderRadius: "20px 20px 120px 120px",
            /*  p: { transform: "rotate(6deg)" }, */
            /* p: { transform: "rotate(0deg)" }, */
          },
        },
        {
          props: { classes: "values5" },
          style: {
            borderRadius: "120px 120px 20px 20px",
            cursor: "pointer",
          },
        },
        {
          props: { classes: "values5_flip" },
          style: {
            borderRadius: "120px 120px 20px 20px",
          },
        },
        {
          props: { classes: "experienceCard" },
          style: {
            textAlign: "left",
            "&.MuiCard-root": {
              height: "345px",
              borderRadius: "32px",
            },
          },
        },
      ],
    },
    MuiCardContent: {
      variants: [
        {
          props: { variant: "card_content1" },
          style: {
            /* transform: "rotate(3deg)", */
          },
        },
        {
          props: { variant: "card_content_flip1" },
          style: {
            /*  transform: "rotate(0deg)", */
          },
        },
        {
          props: { variant: "card_content2" },
          style: {
            /* transform: "rotate(-5deg)", */
          },
        },
        {
          props: { variant: "card_content_flip2" },
          style: {
            /* transform: "rotate(-5deg)", */
          },
        },
        {
          props: { variant: "card_content3" },
          /* style: {
            transform: "rotate(-4deg)",
          }, */
        },
        {
          props: { variant: "card_content_flip3" },
          /* style: {
            transform: "rotate(-10deg)",
          }, */
        },
        {
          props: { variant: "card_content4" },
          /*  style: {
            transform: "rotate(5deg)",
          }, */
        },
        {
          props: { variant: "card_content_flip4" },
          /* style: {
            transform: "rotate(5deg)",
          }, */
        },
        {
          props: { variant: "card_content5" },
          /*  style: {
            transform: "rotate(0deg)",
          }, */
        },
        {
          props: { variant: "card_content_flip1" },
          /* style: {
            transform: "rotate(10deg)",
          }, */
        },
        // {
        //   props: { variant: 'experienceCardContent'},
        //   style: {
        //     '&.MuiCardContent-root': {
        //       hei
        //     }
        //   }
        // }
      ],
    },
    components: {
      // MuiButton: {
      //   variants: [
      //     {
      //       props: { variant: 'contained' },
      //       style: {
      //         // '&:hover': {
      //         //   boxShadow:
      //         //     '3px 6px 0px -4px rgb(24 12 12 / 99%), 3px 2px 2px 0px rgb(0 0 0 / 98%), 3px 3px 0px 0px rgb(0 0 0 / 95%)',
      //         // },

      //         // border: "1px solid black",
      //         fontFamily: FONT.FAMILY.INTER,
      //         fontSize: FONT.SIZES[12],
      //         fontWeight: FONT.WEIGHT.SEMI_BOLD,
      //         padding: '20px',
      //         borderRadius: '25px',
      //       },
      //     },
      // {
      //   props: { variant: 'outlined' },
      //   style: {
      //     border: '1px solid black',
      //     fontFamily: FONT.FAMILY.INTER,
      //     fontSize: FONT.SIZES[12],
      //     fontWeight: FONT.WEIGHT.SEMI_BOLD,
      //     borderRadius: '25px',
      //     color: '#000',
      //   },
      // },
      //   ],
      // },
      MuiAlert: {
        variants: [
          {
            props: { variant: "filled" },
            style: {
              standardSuccess: {
                backgroundColor: "yellow",
                color: "white",
              },
            },
          },
        ],
      },
      MuiAppBar: {
        styleOverrides: {
          // props:{classes:"AppBorder"},
          root: {
            // borderBottom: '1px solid black',
            paddingTop: "1rem",
          },
        },
      },
      MuiLink: {
        variants: [
          {
            props: { classes: "Link" },
            style: {
              borderBottom: "1px solid black",
              paddingTop: "1rem",
              backgroundColor: "red",
            },
          },
        ],
      },

      MuiChip: {
        variants: [
          {
            props: { variant: "tag" },
            style: {
              fontFamily: FONT.FAMILY.INTER,
              fontSize: FONT.SIZES[10],
              fontWeight: FONT.WEIGHT.MEDIUM,
              backgroundColor: PALETTE.PRIMARY,
              padding: "0.6rem",
              margin: "0 0.5rem",
            },
          },
        ],
      },
    },
    MuiSpeedDial: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },
    MuiTab: {
      variants: [
        {
          props: { classes: "values" },
          style: {
            backgroundColor: "#542385",
            borderRadius: "0 100% 100% 0",
            writingMode: "vertical-rl",
            textOrientation: "mixed",
            color: "#fdfdfd",
          },
        },
      ],
    },
    MuiTabs: {
      label: [
        {
          props: { variant: "scrollable" },
          style: {
            writingMode: "vertical-rl",
            textOrientation: "mixed",
          },
        },
      ],
    },
    MuiLink: {
      variants: [
        {
          props: { classes: "hoverable-item" },
          style: {
            display: "none",
          },
        },
      ],
    },
    MuiTextField: {
      variants: [
        {
          props: { variant: "outlined" },
          style: {
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
              textTransform: "uppercase",
            },
            ".MuiOutlinedInput-root": {
              padding: "5px 4px 7.5px 15px",
              borderRadius: "6.25rem !important",
              border: "1.5px solid #241C15",
              height: "3rem",
              width: "23.813rem",
              textTransform: "uppercase",
              "@media (max-width:550px)": {
                width: "auto  ",
              },
            },
          },
        },
        {
          props: { variant: "filled" },
          style: {
            "& .MuiInputBase-root": {
              borderRadius: "10px",
              fontSize: "12px",
              textAlign: "top",
            },

            ".MuiInputBase-input": {
              padding: "16px 0 13px 20px",
            },
            // ".MuiFilledInput-root":{
            //   backgroundColor:"blue"
            // }
            ".MuiInputBase-multiline": {
              padding: "0 0 0 2px",
              height: "106px",
            },
          },
        },
      ],
    },
    MuiDivider: {
      variants: [
        {
          props: { variant: "middle" },
          style: {
            marginLeft: "0px",
            // marginRight: '60px',
            borderStyle: "dotted",
            borderColor: "black",
          },
        },
        {
          props: { variant: "inset" },
          style: {
            marginLeft: "50px",
            marginRight: "50px",
            border: "1px solid rgba(0, 0, 0, 0.2)",
          },
        },
      ],
    },
    MuiCircularProgress: {
      variants: [
        {
          props: { variant: "determinate" },
          style: {
            "&.MuiCircularProgress-root": {
              width: "7.5rem !important",
              height: "7.5rem !important",
            },
            "&.MuiCircularProgress-colorPrimary": {
              color: PALETTE.SECONDARY,
            },
          },
        },
      ],
    },
    MuiFab: {
      variants: [
        {
          props: { variant: "circular" },
          style: {
            "&.MuiFab-root": {
              background: "none",
              boxShadow: "none",
            },
          },
        },
      ],
    },
  },
});
export default theme;
