import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Grid,
  Typography,
  Breadcrumbs,
  Link as HomeLink,
  Stack,
  Avatar,
  Divider,
  List,
  ListItem,
  SpeedDial,
  TextField,
  Button,
  SpeedDialAction,
  Autocomplete,
  InputAdornment,
  CircularProgress,
} from "@mui/material";

import { NavigateNext, Pause } from "@mui/icons-material";
import StickyBox from "react-sticky-box";
import { Link } from "react-scroll";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import iconListen from "../Assets/Images/icon-listen.png";
import iconPdfdownload from "../Assets/Images/icon-pdf-text.png";
import iconPrint from "../Assets/Images/icon-print-text.png";
import iconShare from "../Assets/Images/icon-share-text.png";
import iconSearch from "../Assets/Images/MagnifyingGlass.svg";
import Sanjay from "../Assets/Images/Home/sp-muse-sp.png";
import AuthorName from "../Assets/Images/musing-banner.svg";
import linkedInImage from "../Assets/Images/Icons/White BG Icons/icon-linkedin-collabs.png";
import twitterImage from "../Assets/Images/Icons/White BG Icons/icon-twitter-collabs.png";
import facebookImage from "../Assets/Images/Icons/White BG Icons/icon-fb-collabs.png";
import copyUrlImage from "../Assets/Images/Icons/White BG Icons/icon-copy-text-1.png";
import {
  ArticleLandingImage,
  AvatarImage,
  StickyButton,
  Title,
  ShareBox,
  SliderImage,
} from "../Themes/StyledComponent";
import StoriesAritcle from "../Components/StoriesArticle";
import ReactCompareImage from "react-compare-image";
import axios from "axios";
import StoryCard from "../Components/StoryCard";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import ReactGA from "react-ga4";

const IndiviualArticle = (props, id) => {
  const url = process.env.REACT_APP_API_URL;
  const ref = useRef();
  const [data, setData] = useState();
  const [author, setAuthor] = useState();
  const [fetchedImage, setFetchedImage] = useState();
  const [imageSrc1, setImageSrc1] = useState("");
  const [imageSrc2, setImageSrc2] = useState("");
  const [activeId, setActiveId] = useState(null);
  const [showCard, setShowCard] = useState(false);
  const [showNextCard, setShowNextCard] = useState({});
  const [showCards, setShowCards] = useState([]);
  const location = useLocation();
  const [museHeading, setMuseHeading] = useState("");
  const [museBody, setMuseBody] = useState("");
  const [iconData, setIconData] = useState(false);
  const [authorAudio, setAuthorAudio] = useState(new Audio());
  const [playing, setPlaying] = useState(false);
  const [authorCompanyName, setAuthorCompanyName] = useState("");
  const [authorFetchedImage, setAuthorFetchedImage] = useState("");
  const [readTime, setReadTime] = useState();
  const [downloadPdf, setDownloadPdf] = useState("");
  const [downloadGif, setDownloadGif] = useState("");
  const [metaInfoText, setMetaInfoText] = useState("");

  const [authorTitle, setAuthorTitle] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const paramsUrl = window.location.href.split("?")[1];
  let slug = searchParams.get("slug");
  let stateValue = searchParams.get("post");

  //changing the paramsUrl

  if (paramsUrl.indexOf("post=") === -1) {
    const tempUrl = [
      {
        post: 15,
        newUrl:
          "how-can-governments-and-social-innovators-come-together-to-create-impact",
        oldUrl:
          "how-can-governments-and-social-innovators-come-together-to-create-impact",
      },
      {
        post: 16,
        newUrl: "how-system-orchestrators-create-social-change-symphonies",
        oldUrl: "how-system-orchestrators-create-social-change-symphonies",
      },
      {
        post: 3,
        newUrl: "can-anyone-who-keeps-the-street-clean-become-a-hero",
        oldUrl: "can-anyone-who-keeps-the-street-clean-become-a-hero",
      },
      {
        post: 21,
        newUrl: "how-can-every-young-person-reach-their-potential-by-having-access-to-opportunities",
        oldUrl: "how-can-every-young-person-reach-their-potential-by-having-access-to-opportunities",
      },
      { post: 7, newUrl: "sanjay-purohit", oldUrl: "sanjay-purohit" },
    ];
    tempUrl.forEach((element) => {
      if (element.oldUrl === paramsUrl) {
        navigate(`/article?post=${element.post}&slug=${element.newUrl}`, {
          replace: true,
        });
      }
    });
  }
  // getting the post data  in stringStateValue
  const stringStateValue = parseInt(stateValue);

  useEffect(() => {
    if (window.innerWidth < 768) {
      // viewport width less than 768px (typical mobile device width)
      const element = document.getElementById("divToDownload"); // use a different div element with id "divToDownload"
      element?.scrollIntoView(true);
    } else {
      const element = document.getElementById("divToDownload1"); // use the original div element with id "divToDownload1"
      element?.scrollIntoView({
        block: "nearest",
        inline: "start",
      });
    }
    const text = document.getElementById("article").innerText;
    const wpm = 238;
    const words = text.trim().split(/\s+/).length;
    const time = Math.ceil(words / wpm);
    setReadTime(time);
    locationState();
  }, [slug, data?.content.rendered]);

// getting api data to render the data 
  const locationState = async () => {
    try {
      const json = await axios.get(`${url}wp/v2/posts?_embed&slug=${slug}`);
      setData(json.data[0]);
      json.data = json.data[0];
      const jsonData = json.data?.acf;
      const authorTitle = json.data?.title.rendered;
      const parser = new DOMParser();
      const decodedTitle = parser.parseFromString(
        `<!doctype html><body>${authorTitle}`,
        "text/html"
      ).body.textContent;
      setAuthorTitle(decodedTitle);
      const fImage = json.data?._embedded["wp:featuredmedia"]["0"].source_url;
      setFetchedImage(fImage);
      const sloutionImage = jsonData?.solution_image;
      setImageSrc2(sloutionImage);
      const problemImage = jsonData?.problem_image;
      setImageSrc1(problemImage);
      const museHeading = jsonData?.musing_heading;
      setMuseHeading(museHeading);
      const museBody = jsonData?.musing_body;
      setMuseBody(museBody);
      const audioFile = jsonData?.audio_file;
      setAuthorAudio(new Audio(audioFile));

      const pdflink = jsonData?.pdf;
      setDownloadPdf(pdflink);
      const gifLink = jsonData?.image_gif;
      setDownloadGif(gifLink);
      const metaInfoText = jsonData?.metaInfo;
      setMetaInfoText(metaInfoText);

      const authorName = json.data?._embedded["author"][0].name;
      setAuthor(authorName);
      const authorAvtorImage = json.data?._embedded["author"][0].acf.image;
      setAuthorFetchedImage(authorAvtorImage);
      const authorCompanyName =
        json.data?._embedded["author"][0].acf.company_name;
      setAuthorCompanyName(authorCompanyName);
      const response = await axios.get(`${url}wp/v2/posts?&_embed`);
      let articles = response.data;
      let cards = [];
      for (let index = 0; index < articles.length; index++) {
        const element = articles[index];
        const parser = new DOMParser();
        const contentForTitle = parser.parseFromString(
          element.title.rendered,
          "text/html"
        );
        const title = contentForTitle.body.innerText;
        const excerptContent = parser.parseFromString(
          element.excerpt.rendered,
          "text/html"
        );
        const excerpt = excerptContent.body.innerText.replace("\n", "");
        let fImage = element._embedded["wp:featuredmedia"]
          ? element._embedded["wp:featuredmedia"]["0"].source_url
          : "";
        let card = {
          title,
          excerpt,
          image: fImage,
          author: element._embedded["author"][0].name,
          authorID: element._embedded["author"][0].id,
          slug: element.slug,
        };
        cards = [...cards, card];
      }
      let articlesOrderBySlug = process.env.REACT_APP_SORT;
      const sortedArticles = cards.sort((cardA, cardB) => {
        const indexA = articlesOrderBySlug.split(",").indexOf(cardA.author);
        const indexB = articlesOrderBySlug.split(",").indexOf(cardB.author);
        return indexA - indexB;
      });
      const getCardIndex = sortedArticles.findIndex(
        (item) => item.authorID === stringStateValue
      );
      let nextIndex = (getCardIndex + 1) % sortedArticles.length;
      let nextObject = sortedArticles[nextIndex];
      setShowNextCard(nextObject);
      let nextThreeIndices = [
        (getCardIndex + 2) % sortedArticles.length,
        (getCardIndex + 3) % sortedArticles.length,
        (getCardIndex + 4) % sortedArticles.length,
      ];
      let nextThreeCardObjects = nextThreeIndices.map((i) => sortedArticles[i]);
      setShowCards(nextThreeCardObjects);
    } catch (e) {
      setError(e);
    }
  };

// handling the scroll function to showCards 
  useEffect(() => {
    if (props.scroll > 70) {
      console.log(window.location.href, "window.location.href");
      shareClick("Article", "Scroll", window.location.href);
      setShowCard(true);
    }
    if (props.scroll < 70) {
      setShowCard(false);
      const h2Elements = Array.from(
        document.querySelectorAll(".scrollspy-div h2")
      );
      const activeElement = h2Elements.find(
        (h2) => h2.getBoundingClientRect().top > -1
      );
      setActiveId(activeElement ? activeElement.id : 0);
      let alldivs = document.querySelectorAll('div[id^="s_"]');
      alldivs.forEach((current) => {
        let sectionId = current.getAttribute("id");
        let elementId = document
          .getElementById(sectionId)
          .getElementsByTagName("h2")[0];
        if (elementId) {
          if (`s_${activeId}` === sectionId) {
            if (activeId) {
              elementId.classList.add("my-class");
            }
          } else {
            elementId.classList.remove("my-class");
          }
        }
      });
    }
  }, [activeId, props.scroll]);

//routing to the particular path 
  const breadcrumbs = [
    <HomeLink
      underline="none"
      key="1"
      sx={{ color: "#241C15", fontSize: "10px", fontWeight: "600" }}
      href="/#/"
    >
      HOME
    </HomeLink>,
    <HomeLink
      underline="none"
      key="2"
      sx={{ color: "#241C15", fontSize: "10px", fontWeight: "600" }}
      href="/#/articles"
    >
      Edition One
    </HomeLink>,
    <HomeLink
      underline="none"
      key="2"
      sx={{ color: "#885CA1", fontSize: "10px", fontWeight: "600" }}
    >
      {authorTitle ? authorTitle.toUpperCase() : ""}
    </HomeLink>,
  ];
// urls
  const shareUrl = `${window.location.protocol}//${window.location.host}/#/article?post=${stringStateValue}&slug=${slug}`;
  const cardUrl = `${window.location.protocol}//${window.location.host}/#/article?post=${stringStateValue}&slug=${slug}`;
// google analytics events 
  const shareClick = (category, action, label) => {
    ReactGA.event({
      category,
      action,
      label,
    });
  };
// share icons images and links 
  const actions = [
    {
      icon: (
        <LinkedinShareButton
          url={shareUrl}
          onClick={shareClick("Share", "Linkedin", shareUrl)}
        >
          <img
            src={linkedInImage}
            url={shareUrl}
            style={{ height: "100%", width: "100%" }}
            alt="img"
          />
        </LinkedinShareButton>
      ),
      name: "LinkedIn",
    },
    {
      icon: (
        <FacebookShareButton
          url={shareUrl}
          onClick={shareClick("Share", "Facebook", shareUrl)}
        >
          <img
            src={facebookImage}
            style={{ height: "90%", width: "100%" }}
            alt="img"
          />
        </FacebookShareButton>
      ),
      name: "Facebook",
    },
    {
      icon: (
        <TwitterShareButton
          url={shareUrl}
          onClick={shareClick("Share", "Twitter", shareUrl)}
        >
          <img
            src={twitterImage}
            style={{ height: "100%", width: "100%" }}
            alt="img"
          />
        </TwitterShareButton>
      ),
      name: "Twitter",
    },
    {
      icon: (
        <img
          src={copyUrlImage}
          url={cardUrl}
          style={{ height: "100%", width: "100%" }}
          alt="img"
        />
      ),
      name: iconData ? "copied" : "Copy URL",
    },
  ];
  // copying link function
  const copyToClipboard = (text) => {
    const textToCopy = `Read this story of exponential societal change on ${text}`;
    navigator.clipboard.writeText(textToCopy).then(
      (res) => {
        shareClick("Share", "Copied to clipboard", text);
        setIconData(true);
      },
      (err) => {
        setIconData(false);
      }
    );
  };

  const styles = {
    LandingBackground: {
      backgroundColor: "#fff",
    },
  };
  // function t get the sidebar list 
  let result;
  let extractedH2;
  if (data?.content?.rendered.match(/<h(.)>.*?<\/h\1>/gs)) {
    const filteredValue = data?.content?.rendered
      .match(/<h(.)>.*?<\/h\1>/gs)
      .map((x, i) => i);

    if (filteredValue) {
      result = data?.content.rendered.replace(/<h2>/g, function () {
        return `<h2 id="${filteredValue.shift()}">`;
      });
      extractedH2 = data?.content.rendered.match(
        /<h(.)>.*?<\/h\1>/gs,
        function () {
          return `<h2 id="${filteredValue.shift()}">`;
        }
      );
    }
  }

  // Text to speech functionality
  const playAudio = () => {
    if (!playing) {
      setPlaying(true);
      authorAudio.play();
      shareClick("Audio", "Play", window.location.href);
    } else {
      setPlaying(false);
      authorAudio.pause();
    }
  };
   // pause the Text to speech functionality when the page changes
  useEffect(() => {
    return () => {
      setPlaying(false);
      authorAudio.pause();
    };
  }, [authorAudio]);

  // Download PDF file
  const reportTemplateRef = useRef();

  const downloadPdfDocument = async (pdfUrl, gifUrl, authorTitle) => {
    try {
      const pdfResponse = await fetch(pdfUrl);
      const pdfBlob = await pdfResponse.blob();
      const pdfBlobUrl = URL.createObjectURL(pdfBlob);
      const pdfLink = document.createElement("a");
      pdfLink.href = pdfBlobUrl;
      pdfLink.download = authorTitle;
      document.body.appendChild(pdfLink);
      pdfLink.click();
      document.body.removeChild(pdfLink);
      URL.revokeObjectURL(pdfBlobUrl);
      // if (gifUrl !== false) {
      //   const gifResponse = await fetch(gifUrl);
      //   const gifBlob = await gifResponse.blob();
      //   const gifBlobUrl = URL.createObjectURL(gifBlob);
      //   const gifLink = document.createElement("a");
      //   gifLink.href = gifBlobUrl;
      //   gifLink.download = authorTitle;
      //   document.body.appendChild(gifLink);
      //   gifLink.click();
      //   document.body.removeChild(gifLink);
      //   URL.revokeObjectURL(gifBlobUrl);
      // }
      shareClick("Download", "PDF", pdfUrl);
    } catch (error) {
      console.error(error);
    }
  };

  // print pdf file
  const printPdfDocument = async (pdfUrl) => {
    try {
      const pdfWindow = window.open(pdfUrl, "");
      pdfWindow.onload = () => {
        shareClick("Print", "PDF", pdfUrl);
        pdfWindow.print();
      };
    } catch (error) {
      console.error(error);
    }
  };

  // onsearch scroll function
  const onBtnClick = (id) => {
    const element = document.getElementById(id);
    const headerOffset = 45;
    const elementPosition = ref.current.getBoundingClientRect().top - 500;
    const offsetPosition =
      elementPosition + ref.current.offsetTop - headerOffset;
    element.scrollIntoView({
      top: offsetPosition,
      behavior: "smooth",
    });
  };
  // on sidebar click scroll function
  const onBtnClick1 = (id) => {
    document.getElementById(id).scrollIntoView({ block: "start" });
  };

  return (
    <>
      {data !== null && data !== [] && (
        <div style={styles.LandingBackground} id="divToDownload">
          <Helmet>
            <meta charSet="utf-8" />
            <meta name="description" content={metaInfoText} />
            <meta property="og:image" content={fetchedImage} />
          </Helmet>
          <Box
            ref={reportTemplateRef}
            elevation={1}
            ml={{ xs: "0.1rem", sm: "3.5rem" }}
          >
            <Stack
              id="divToDownload1"
              spacing={2}
              pt="2.5rem"
              display={{ xs: "none", sm: "block" }}
            >
              <Breadcrumbs
                separator={<NavigateNext fontSize="small" />}
                aria-label="breadcrumb"
              >
                {breadcrumbs}
              </Breadcrumbs>
            </Stack>
            <Grid
              container
              direction={{ xs: "column-reverse", sm: "row", md: "row" }}
              justifyContent="center"
              alignItems="center"
              pt={{ xs: 10, sm: 2 }}
            >
              <Grid item xs={12} sm={7} md={7} sx={{ textAlign: "left" }}>
                <Title>
                  <div id="AuthorDetails">
                    <div id="headerText">
                      <Typography
                        variant="h1"
                        dangerouslySetInnerHTML={{
                          __html: data?.excerpt.rendered,
                        }}
                        p={{ xs: 2, sm: 0 }}
                      />
                    </div>
                  </div>
                </Title>
                <Grid
                  container
                  direction="row"
                  justifyContent={{ xs: "center", sm: "flex-start" }}
                  alignItems="center"
                  mt={{ xs: 1, sm: 6 }}
                  spacing={{ xs: 2, md: 0 }}
                >
                  <Grid item xs={11} md={7}>
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      spacing={4}
                    >
                      <Grid item xs={3} sm={2} md={3} lg={2}>
                        {" "}
                        <AvatarImage
                          onClick={() => {
                            navigate(`/authors`, {
                              replace: true,
                              state: stringStateValue,
                            });
                          }}
                        >
                          <Avatar
                            alt="Remy Sharp"
                            src={authorFetchedImage ? authorFetchedImage : ""}
                            sx={{
                              width: 80,
                              height: 80,
                              border: "1px solid black",
                              "@media (max-width:1650px)": {
                                width: 60,
                                height: 60,
                              },
                              "@media (max-width:550px)": {
                                width: 56,
                                height: 56,
                              },
                            }}
                          />
                        </AvatarImage>
                      </Grid>
                      <Grid item xs={8} pl={{ lg: 2, xl: 0 }}>
                        <Typography variant="body2">WORDS BY</Typography>
                        <Typography
                          variant="body2"
                          onClick={() => {
                            navigate(`/authors`, {
                              replace: true,
                              state: stringStateValue,
                            });
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <b>{author ? author : ""}</b>
                        </Typography>
                        <Typography sx={{ color: "#885CA1" }}>
                          {authorCompanyName ? authorCompanyName : ""}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={5} md={5}>
                <div id="headerImage">
                  <ArticleLandingImage>
                    <img
                      // style={{ width: '606px', height: '630px' }}
                      src={fetchedImage ? fetchedImage : "No image"}
                      alt="img"
                    />
                  </ArticleLandingImage>
                </div>
              </Grid>
            </Grid>
            <Divider variant="middle" />
            <Grid
              container
              direction="row"
              justifyContent={{ xs: "flex-start", md: "flex-start" }}
              alignItems="center"
              mt={5}
            >
              <Grid item xs={4} md={3} sx={{ textAlign: "start" }}>
                <Autocomplete
                  freeSolo
                  id="free-solo-2-demo"
                  disableClearable
                  options={extractedH2?.map((elem, index) => {
                    return { label: elem.replace(/<[^>]+>/g, ""), id: index };
                  })}
                  onChange={(e, value) => {
                    onBtnClick(value.id);
                  }}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        placeholder="SEARCH"
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              sx={{ marginRight: "10px" }}
                            >
                              <img src={iconSearch} alt="img" />
                            </InputAdornment>
                          ),
                        }}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={6} md={8}>
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={2}
                >
                  <Button
                    variant="audio-btn"
                    onClick={playAudio}
                    endIcon={
                      playing ? (
                        <Pause />
                      ) : (
                        <img
                          style={{ height: "1.5rem", width: "1.5rem" }}
                          src={iconListen}
                          alt="img"
                        />
                      )
                    }
                  >
                    LISTEN
                  </Button>
                  <Box display={{ xs: "none", md: "block" }}>
                    <Button
                      download
                      onClick={() =>
                        downloadPdfDocument(
                          downloadPdf,
                          downloadGif,
                          authorTitle
                        )
                      }
                    >
                      <img
                        style={{ height: "3.4rem", width: "3.4rem" }}
                        src={iconPdfdownload}
                        alt="img"
                      />
                    </Button>
                  </Box>
                  <Box display={{ xs: "none", md: "block" }}>
                    {" "}
                    <Button onClick={() => printPdfDocument(downloadPdf)}>
                      <img
                        style={{ height: "3.4rem", width: "3.4rem" }}
                        src={iconPrint}
                        alt="img"
                      />
                    </Button>
                  </Box>

                  <ShareBox
                    sx={{
                      transform: "translateZ(0px)",
                    }}
                  >
                    <SpeedDial
                      ariaLabel="SpeedDial basic example"
                      sx={{
                        position: "absolute",
                        bottom: "-1.8rem",
                        "& .MuiFab-primary": {
                          boxShadow: "none",
                        },
                      }}
                      icon={
                        <img
                          style={{ height: "3.4rem", width: "3.4rem" }}
                          src={iconShare}
                          alt="img"
                        />
                      }
                    >
                      {actions.map((action, index) => (
                        <SpeedDialAction
                          sx={{
                            boxShadow: "none",
                            alignContent: "center",
                          }}
                          onClick={() => copyToClipboard(cardUrl)}
                          key={index}
                          icon={action.icon}
                          tooltipTitle={action.name}
                        />
                      ))}
                    </SpeedDial>
                  </ShareBox>
                </Stack>
              </Grid>
            </Grid>
            <Grid container mt={{ xs: 2, sm: 5, md: 10 }} spacing={2} pb={5}>
              {!showCard ? (
                <Grid
                  item
                  xs={12}
                  md={4}
                  sx={{ textAlign: "left", px: { xs: 3, sm: 0 } }}
                  display={{ xs: "none", sm: "block" }}
                >
                  <StickyBox offsetTop={20} offsetBottom={20}>
                    <Box
                      sx={{
                        position: "relative",
                        display: "inline-flex",
                        alignItems: "center",
                      }}
                    >
                      <CircularProgress
                        variant="determinate"
                        sx={{
                          " &.MuiCircularProgress-root": {
                            color: "rgb(128,128,128, 0.2)",
                          },
                        }}
                        size={40}
                        thickness={3}
                        {...props}
                        value={100}
                      />
                      <CircularProgress
                        thickness={3}
                        variant="determinate"
                        value={props.scroll}
                        sx={{ position: "absolute" }}
                      />
                      <Box
                        sx={{
                          top: 0,
                          left: 0,
                          bottom: 0,
                          right: 0,
                          position: "absolute",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography sx={{ textAlign: "center" }}>
                          <span id="time" style={{ fontSize: "40px" }}>
                            {readTime}
                          </span>
                          <br /> min read
                        </Typography>
                      </Box>
                    </Box>
                    <Box mt={{ xs: 2, sm: 6 }}>
                      <Typography
                        variant="h5"
                        sx={{
                          color: "#241C1599",
                          fontWeight: "600",
                          pb: 2,
                          mb: 2,
                        }}
                      >
                        {" "}
                        ARTICLE OUTLINE
                      </Typography>
                      <List sx={{ pl: { xs: 2, sm: 0 } }}>
                        {extractedH2?.map((elem, i) => (
                          <ListItem sx={{ pl: { xs: 1, sm: 0 } }}>
                            <StickyButton>
                              <Link
                                onClick={() => onBtnClick1(i)}
                                activeClass="active"
                                key={i}
                                to={i}
                                spy={true}
                                smooth={true}
                                offset={-80}
                                duration={500}
                              >
                                <div
                                  id={`s_${i}`}
                                  dangerouslySetInnerHTML={{
                                    __html: elem,
                                  }}
                                />
                              </Link>
                            </StickyButton>
                          </ListItem>
                        ))}
                      </List>
                    </Box>
                  </StickyBox>
                </Grid>
              ) : (
                <Grid
                  item
                  xs={12}
                  md={4}
                  sx={{ textAlign: "left", px: { xs: 3, sm: 0 } }}
                  display={{ xs: "none", sm: "block" }}
                >
                  <StickyBox offsetTop={20} offsetBottom={20}>
                    <Box
                      sx={{
                        position: "relative",
                        display: "inline-flex",
                        alignItems: "center",
                      }}
                    >
                      <CircularProgress
                        variant="determinate"
                        sx={{
                          " &.MuiCircularProgress-root": {
                            color: "rgb(128,128,128, 0.2)",
                          },
                        }}
                        size={40}
                        thickness={3}
                        {...props}
                        value={100}
                      />
                      <CircularProgress
                        thickness={3}
                        variant="determinate"
                        value={props.scroll}
                        sx={{ position: "absolute" }}
                      />
                      <Box
                        sx={{
                          top: 0,
                          left: 0,
                          bottom: 0,
                          right: 0,
                          position: "absolute",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography sx={{ textAlign: "center" }}>
                          <span id="time" style={{ fontSize: "40px" }}>
                            {readTime}
                          </span>
                          <br /> min read
                        </Typography>
                      </Box>
                    </Box>
                    <Box sx={{ mt: 5 }}>
                      <Typography
                        variant="body2"
                        sx={{
                          pb: 2,
                          textTransform: "uppercase",
                          mb: -3,
                          ml: 3,
                        }}
                      >
                        Read Next
                      </Typography>
                      {showNextCard && (
                        <>
                          <Box sx={{ width: { md: "100%", lg: "22rem" } }}>
                            <StoryCard
                              title={showNextCard.title}
                              blurb={showNextCard.excerpt}
                              Author={showNextCard.author}
                              images={showNextCard.image}
                              id={showNextCard.authorID}
                              slug={showNextCard.slug}
                            />
                          </Box>
                        </>
                      )}
                    </Box>
                  </StickyBox>
                </Grid>
              )}
              <Grid
                item
                xs={12}
                md={7}
                sx={{ textAlign: "left" }}
                px={{ xs: 4, md: 5 }}
                ref={ref}
              >
                <Title>
                  <Box sx={{ px: { xs: 2, sm: 0 } }}>
                    <div className="scrollspy-div">
                      <div
                        id="article"
                        dangerouslySetInnerHTML={{
                          __html: result,
                        }}
                      />
                    </div>
                  </Box>
                </Title>
                {imageSrc2 && imageSrc1 && (
                  <>
                    <SliderImage className="sliderhttp">
                      <ReactCompareImage
                        sliderLineColor="black"
                        leftImage={imageSrc2}
                        rightImage={imageSrc1}
                      />
                    </SliderImage>
                    <b>
                      <Typography textAlign="center" pt={1}>
                        Move the slider to reveal the arch
                      </Typography>
                    </b>
                  </>
                )}
              </Grid>
            </Grid>
            {AuthorName && museHeading && museBody && (
              <Grid
                container
                direction={{ xs: "column-reverse", sm: "row" }}
                my={10}
                alignItems="flex-end"
                px={5}
              >
                <Grid item xs={12} md={4} sx={{ textAlign: "left" }}>
                  <div id="footerImage">
                    <img src={Sanjay} width="80%" alt="img" />
                  </div>
                </Grid>
                <Grid item xs={12} md={7} sx={{ textAlign: "left" }}>
                  <div id="footerAuthor">
                    <Box
                      sx={{
                        background: ` url(${AuthorName}) center left no-repeat`,
                        backgroundSize: "11.875rem",
                        pl: 1,
                        mb: 3,
                        py: 1,
                      }}
                    >
                      <Typography variant="h6">SANJAY'S MUSINGS</Typography>
                    </Box>

                    <Typography variant="body">
                      <b> {museHeading ? museHeading : ""} </b>
                      <br />
                      {museBody ? museBody : ""}
                    </Typography>

                    <Typography variant="h6" sx={{ pt: 3, fontWeight: 600 }}>
                      Sanjay Purohit
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ pb: 2, textTransform: "uppercase" }}
                    >
                      Chief Curator
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            )}
          </Box>
          {showCards && <StoriesAritcle showCards={showCards} />}
        </div>
      )}
    </>
  );
};

export default IndiviualArticle;
