import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './ButtonLink.css';
import axios from 'axios';
import { Link } from 'react-router-dom';

const MuseButton = ({
  title,
  link,
  id,
  slug,
  postId,
  routePathTo,
  routePath,
}) => {
  const url = process.env.REACT_APP_API_URL;
  const [museButton, setMuseButton] = useState(false);
  const onMouseEnter = (isOpen) => {
    setMuseButton(true);
  };

  const onMouseLeave = (isOpen) => {
    setMuseButton(false);
  };
  const navigate = useNavigate();
  const funReadAirticle = async (id) => {
    if (id) {
      const response = await axios.get(`${url}wp/v2/posts?author=${id}`);
      navigate(
        `/article?post=${response.data[0].author}&slug=${response.data[0].slug}`
      );
    }
  };

  return (
    <>
      {postId && (
        <a
          target=""
          className={`cta-btn ${museButton ? 'on' : ''}`}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onClick={() => {
            funReadAirticle(postId);
          }}
        >
          <span class="hover-frame" data-num="1"></span>
          <span class="hover-frame" data-num="2"></span>
          {title}
        </a>
      )}
      {id && slug && (
        <Link to={`/article?post=${id}&slug=${slug}`}>
          <a
            target=""
            className={`cta-btn ${museButton ? 'on' : ''}`}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            <span class="hover-frame" data-num="1"></span>
            <span class="hover-frame" data-num="2"></span>
            {title}
          </a>
        </Link>
      )}
      {routePathTo && (
        <Link to={`/experience`}>
          <a
            target=""
            className={`cta-btn ${museButton ? 'on' : ''}`}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            <span class="hover-frame" data-num="1"></span>
            <span class="hover-frame" data-num="2"></span>
            {title}
          </a>
        </Link>
      )}
      {routePath && (
        <Link to={`/authors`}>
          <a
            target=""
            className={`cta-btn ${museButton ? 'on' : ''}`}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            <span class="hover-frame" data-num="1"></span>
            <span class="hover-frame" data-num="2"></span>
            {title}
          </a>
        </Link>
      )}
    </>
  );
};

export default MuseButton;
