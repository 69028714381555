import React from 'react';
import { Grid, Typography, Container } from '@mui/material';
import { LandingImage, Section } from '../Themes/StyledComponent';
import Divider from '../Assets/Images/underline-white.png';
import art from '../Assets/Images/Home/sp-muse-books-white.png';

const ShareLandingPage = (props) => {
  return (
    <>
      <Section id={props.id} pt={{ xs: 4, sm: 14, md: 13 }}>
        <Container sx={{ position: 'relative' }}>
          <Grid
            container
            spacing={0}
            justifyContent="start"
            alignItems="start"
            py={{ xs: 4, sm: 4, md: 5 }}
            direction={{ xs: 'column-reverse', sm: 'row' }}
          >
            <Grid item xs={12} sm={12} md={6} lg={6} sx={{ textAlign: 'left' }}>
              <Typography variant="h1" textAlign="Left" pb={3}>
                <b>
                  {' '}
                  Sharing = <br />
                  <span
                    style={{
                      background: ` url(${Divider}) bottom no-repeat`,
                      paddingBottom: '05px',
                      backgroundSize: 'contain',
                    }}
                  >
                    Exponential
                  </span>
                </b>
              </Typography>
              <Typography variant="body" py={{ xs: 2, sm: 2, md: 18 }}>
                At Societal Thinking, we believe knowledge is for, of and by
                everyone and when shared, it grows exponentially. As creators
                and curators of knowledge, all our work is under the Creative
                Commons licence, CC BY SA 4.0 International. <br />
                We invite you to share, build upon and remix our work and take
                the knowledge farther.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={{ textAlign: 'left' }}>
              <LandingImage>
                <img src={art} width="100%" alt="img" />
              </LandingImage>
            </Grid>
          </Grid>
          
        </Container>
      </Section>
    </>
  );
};

export default ShareLandingPage;
