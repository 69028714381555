import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
  Snackbar,
  Stack,
  Slide,
} from '@mui/material';
import { useState } from 'react';
import React from 'react';
import { LandingImage, Section } from '../Themes/StyledComponent';
import art from '../Assets/Images/Home/sp-muse-books-black.png';
import MuiAlert from '@mui/material/Alert';
import { makeStyles } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import LinkedInIconImage from '../Assets/Images/Icons/icon-linkedin.png';
import TwitterIconImage from '../Assets/Images/Icons/icon-twitter.png';

import axios from 'axios';
const useStyles = makeStyles(() => ({
  helperText: {
    color: 'red',
    opacity: 1,
    fontSize: 14,
  },
}));
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Contact = () => {
  const classes = useStyles();
  const validationSchema = Yup.object().shape({
    Name: Yup.string().required('Name is required'),
    Email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
    Organization: Yup.string().required('Organisation is required'),
  });
  const formik = useFormik({
    initialValues: { Name: '', Email: '', Organization: '', Message: '' },
    validationSchema,
    onSubmit: (values) => {
      let formEle = document.querySelector('form');
      const formdata = new FormData(formEle);
      let formNumber = 2948;
      const url = 'https://musedashboard.in/';
      axios
        .post(
          `${url}/wp-json/contact-form-7/v1/contact-forms/${formNumber}/feedback`,
          formdata
        )
        .then((res) => {
          console.log(res);
          setState({
            open: true,
            vertical: 'bottom',
            horizontal: 'center',
          });
          setNote('Thank you for reaching us ');
          setSeverity('success');
          document.querySelector('form').reset();
          values.Name = '';
          values.Email = '';
          values.Organization = '';
          values.Message = '';
        })
        .catch((err) => {
          console.log(err);
        });
    },
  });
  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const [note, setNote] = useState('');
  const styles = {
    LandingBackground1: {
      backgroundColor: '#EDEBEA',
      borderRadius: '30px 0px 30px 30px ',
    },
  };
  const [state, setState] = React.useState({
    open: false,
    vertical: 'bottom',
    horizontal: 'center',
  });
  const { vertical, horizontal, open } = state;
  const [severity, setSeverity] = useState();

  return (
    <>
      <Box style={styles.LandingBackground1} py={5}>
        <Section pt={{ xs: 4, sm: 14, md: 13 }}>
          <Container>
            <Grid
              container
              spacing={0}
              direction={{ xs: 'column-reverse', sm: 'row' }}
              justifyContent="space-between"
              alignItems="center"
              pt={0}
            >
              <Grid item xs={12} sm={12} md={6} lg={6} textAlign="left" pb={3}>
                <Box display={{ xs: 'none', md: 'block' }}>
                  <Typography variant="h2" textAlign="Left" color="text-white">
                    We’d love to hear
                    <br /> from you!
                  </Typography>
                </Box>

                <Box py={3}>
                  <Typography variant="h5">
                    Write to us at
                    <br />
                    <a
                      href="mailto:https://info@societalthinking.org"
                      target="_blank"
                    >
                      info@societalthinking.org
                    </a>{' '}
                    <br />
                    or drop us a message by filling out the form.
                  </Typography>
                  <Stack
                    spacing={0}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Box width="2rem" pt={1}>
                      <a
                        href="https://www.linkedin.com/company/societal-platform/"
                        target="_blank"
                      >
                        <img src={LinkedInIconImage} width="70%" alt="img" />
                      </a>
                    </Box>
                    <Box width="2rem" pt={1}>
                      <a
                        href="https://twitter.com/SocietalPlatform"
                        target="_blank"
                      >
                        <img src={TwitterIconImage} width="70%" alt="img" />
                      </a>
                    </Box>
                  </Stack>
                </Box>
                <LandingImage
                  mt={{ xs: -5, sm: -12 }}
                  display={{ xs: 'none', sm: 'block' }}
                >
                  <img src={art} width="100%" alt="img" />
                </LandingImage>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} textAlign="left" pb={3}>
                <Box
                  sx={{
                    p: { xs: 1, sm: 2, md: 4 },
                    backgroundColor: '#fff',
                    borderRadius: '10px',
                  }}
                >
                  <Box display={{ xs: 'block', md: 'none' }}>
                    <Typography
                      variant="h2"
                      textAlign="Left"
                      color="text-white"
                    >
                      We’d love to hear from you!
                    </Typography>
                  </Box>
                  <form
                    className="form"
                    onSubmit={(e) => {
                      e.preventDefault();
                      formik.handleSubmit();
                    }}
                  >
                    <TextField
                      autoComplete="off"
                      style={{ textAlign: 'center' }}
                      InputProps={{ disableUnderline: true }}
                      className={classes.textfield}
                      id="filled-basic"
                      value={formik.values.Name}
                      placeholder="Name"
                      variant="filled"
                      fullWidth
                      margin="dense"
                      onChange={formik.handleChange}
                      name="Name"
                      sx={{ borderRadius: '30px' }}
                      FormHelperTextProps={{ className: classes.helperText }}
                    />{' '}
                    {formik.errors.Name && formik.touched.Name ? (
                      <div style={{ color: 'red' }}>{formik.errors.Name}</div>
                    ) : null}
                    <br />
                    <TextField
                      autoComplete="off"
                      className={classes.textfield}
                      InputProps={{ disableUnderline: true }}
                      id="filled-basic"
                      placeholder="Email"
                      variant="filled"
                      fullWidth
                      margin="normal"
                      py={4}
                      name="Email"
                      value={formik.values.Email}
                      onChange={formik.handleChange}
                      FormHelperTextProps={{ className: classes.helperText }}
                    />{' '}
                    {formik.errors.Email && formik.touched.Email ? (
                      <div style={{ color: 'red' }}>{formik.errors.Email}</div>
                    ) : null}
                    <TextField
                      autoComplete="off"
                      className={classes.textfield}
                      InputProps={{ disableUnderline: true }}
                      id="filled-basic"
                      placeholder="Organisation"
                      variant="filled"
                      fullWidth
                      margin="normal"
                      value={formik.values.Organization}
                      name="Organization"
                      onChange={formik.handleChange}
                      FormHelperTextProps={{ className: classes.helperText }}
                    />{' '}
                    {formik.errors.Organization &&
                      formik.touched.Organization ? (
                      <div style={{ color: 'red' }}>
                        {formik.errors.Organization}
                      </div>
                    ) : null}
                    <TextField
                      autoComplete="off"
                      className={classes.textfield}
                      InputProps={{ disableUnderline: true }}
                      id="filled-multiline-static"
                      placeholder="Message"
                      multiline
                      fullWidth
                      margin="normal"
                      rows={4}
                      variant="filled"
                      value={formik.values.Message}
                      name="Message"
                      onChange={formik.handleChange}
                      FormHelperTextProps={{ className: classes.helperText }}
                    />
                    <Button
                      variant="outlined"
                      size="large"
                      style={{ borderColor: '#000' }}
                      type="submit"
                      sx={{
                        color: '#000',
                        borderRadius: '25px',
                        fontWeight: 600,
                        px: 5,
                        width: '100%',
                        mr: 15,
                        py: 2,
                        mt: 2,
                      }}
                      fullwidth
                    >
                      Submit form
                    </Button>
                  </form>
                  <div>
                    {' '}
                    <Stack spacing={2} sx={{ maxWidth: 600 }}>
                      <Snackbar
                        anchorOrigin={{ vertical, horizontal }}
                        open={open}
                        autoHideDuration={6000}
                        onClose={handleClose}
                        message={note}
                        key={vertical + horizontal}
                        TransitionComponent={(props) => {
                          return <Slide {...props} direction="up" />;
                        }}
                      >
                        <Alert
                          onClose={handleClose}
                          severity={severity}
                          sx={{ width: '100%' }}
                        >
                          {note}
                        </Alert>
                      </Snackbar>
                    </Stack>
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Section>
      </Box>
    </>
  );
};
export default Contact;
