import { styled } from '@mui/system';
import {
  Box,
  Button,
  Container,
  Typography,
  Card,
  Stack,
  IconButton,
} from '@mui/material';
import { Link } from "react-router-dom";

import FONT from '../Themes/Font';

import Scribble from '../Assets/Images/Icons/ArticleLine.png';
import Scribble2 from '../Assets/Images/Vector 67.png';
// import ScribbleCircle from "../Assets/Images/scribble-circle.png";
// import ScribbleCircleSVG from "../Assets/Images/scribble-circle.svg";

import Art from '../Assets/Images/Home/sp-muse-books-black.png';
import ShareIcon from '@mui/icons-material/Share';
import Carousel from 'react-material-ui-carousel';

import StickyBox from 'react-sticky-box';

export const StickyButton = styled(Button)`
  border: none;
  height: 1.5rem;
  background: none;
  text-transform: initial;
  padding-left: 0px;
  padding-bottom: 1.5rem;
  &:hover {
    background: ${`url(${Scribble2}) bottom  no-repeat`};
    background-size: contain;
    padding-bottom: 1.5rem;
  }
  h2 {
    font-family: ${FONT.FAMILY.CAMPAIGN};
    font-weight: ${FONT.WEIGHT.REGULAR};
    font-size: 18px;
    color: #241c15;
    line-height: 18px;
    padding-bottom: 0.2rem;
    text-align: left;

    :hover {
      color: #865ba0;
    }
  }
  .my-class {
    font-family: ${FONT.FAMILY.CAMPAIGN};
    font-weight: ${FONT.WEIGHT.REGULAR};
    font-size: 18px;
    color: #865ba0;
    padding-bottom: 0.2rem;
    // background: ${`url(${Scribble2}) bottom  no-repeat`};
    // background-size: contain;
    line-height: 18px;
    text-align: left;
  }
  @media print {
    body {
      display: none;
    }
    #printContainer {
      display: block;
    }
  }
`;
export const ExperienceImage = styled('Box')`
  .harambeeDotted {
    height: 4.375rem;
    width: 8.125rem;
    border-bottom-left-radius: 6.875rem;
    border-bottom-right-radius: 6.875rem;
    border-bottom: 0;
    margin-top: 10rem;
    transform: rotate(-37deg);
    margin-left: 8.4375rem;
    @media (max-width: 550px) {
      height: 4.375rem;
      width: 8.125rem;
      border-bottom-left-radius: 6.875rem;
      border-bottom-right-radius: 6.875rem;
      border-bottom: 0;
      margin-top: 153px;
      transform: rotate(-38deg);
    }
    img {
      width: 7.5rem;
      height: 6.1875rem;
      margin-top: -2rem;
      margin-left: 0.3125rem;
      transform: rotate(33deg);
      @media (max-width: 1650px) {
        width: 5.25rem;
        height: 4.625rem;
        margin-top: -3rem;
        margin-right: 0.3125rem;
      }
      @media (max-width: 550px) {
        width: 72px;
        height: 62px;
        margin-top: -48px;
        margin-right: 7px;
        transform: rotate(35deg);
      }
    }
  }
  .benifitDotted {
    height: 7.1875rem;
    width: 15.75rem;
    border-bottom-left-radius: 6.875rem;
    border-bottom-right-radius: 6.875rem;
    border-bottom: 0;
    margin-top: 19.5rem;
    margin-left: -2.5rem;
    img {
      width: 15.5rem;
      margin-top: -4rem;
      height: 15.4375rem;
      @media (max-width: 1650px) {
        width: 10.875rem;
        margin-top: -4.6875rem;
        height: 10.9375rem;
        margin-left: 0.6875rem;
      }
      @media (max-width: 550px) {
        width: 9.375rem;
        margin-top: -4.9375rem;
        height: 9.375rem;
        margin-left: 1.0625rem;
      }
    }
  }
  .citymartDotted {
    height: 5rem;
    width: 12.5rem;
    border-bottom-left-radius: 6.875rem;
    border-bottom-right-radius: 6.875rem;
    border-bottom: 0;
    margin-top: 0.5rem;
    margin-left: 0;
    img {
      width: 12.25rem;
      margin-top: -3.1rem;
      height: 11.875rem;
      @media (max-width: 1650px) {
        width: 8.5625rem;
        margin-top: -1.475rem;
        height: 8.25rem;
      }
      @media (max-width: 550px) {
        width: 7.25rem;
        margin-top: -0.9125rem;
        height: 7rem;
      }
    }
  }
  .shikshalokamDotted {
    height: 3.6875rem;
    width: 7.9375rem;
    border-bottom-left-radius: 6.875rem;
    border-bottom-right-radius: 6.875rem;
    border-bottom: 0;
    margin-top: 10.1rem;
    margin-left: 5.3125rem;
    transform: rotate(-2deg);
    img {
      width: 11.875rem;
      margin-top: -2.1rem;
      height: 7.875rem;
      @media (max-width: 1650px) {
        width: auto;
        margin-top: -2.375rem;
        height: 5.4375rem;
        margin-right: 1.4375rem;
      }
      @media (max-width: 550px) {
        width: 4.6875rem;
        margin-top: -2.5375rem;
        height: 4.75rem;
        margin-right: 2rem;
      }
    }
  }
  .seedsDotted {
    height: 5.6875rem;
    width: 11.875rem;
    border-top-left-radius: 6.875rem;
    border-top-right-radius: 6.875rem;
    border-bottom: 0;
    margin-top: -6.7rem;
    margin-left: 3.625rem;
    transform: rotate(5deg);
    img {
      width: 11.5625rem;
      height: 5.9375rem;
      transform: rotate(3deg);
      @media (max-width: 1650px) {
        width: 8.1875rem;
        height: 4.25rem;
        transform: rotate(3deg);
        margin-top: 1.9375rem;
        margin-right: 0.875rem;
      }
      @media (max-width: 550px) {
        width: 7.0625rem;
        margin-top: 2.625rem;
        height: 3.5625rem;
        margin-right: 1.1875rem;
      }
    }
  }
  .echoDotted {
    height: 5.8125rem;
    width: 12.875rem;
    border-bottom-left-radius: 6.875rem;
    border-bottom-right-radius: 6.875rem;
    border-bottom: 0;
    margin-top: 17.5rem;
    img {
      width: 12.8125rem;
      height: 12.4375rem;
      margin-top: -3.3125rem;
      @media (max-width: 1650px) {
        width: 142px;
        height: 141px;
        margin-top: -66px;
      }
      @media (max-width: 550px) {
        width: 7.625rem;
        margin-top: -4.375rem;
        height: 7.5rem;
      }
    }
  }
  .egovDotted {
    height: 5.8125rem;
    width: 12.5rem;
    border-bottom-left-radius: 6.875rem;
    border-bottom-right-radius: 6.875rem;
    border-bottom: 0;
    margin-bottom: 10.3125rem;
    margin-left: 6.25rem;
    transform: rotate(243deg);
    img {
      width: 11.4375rem;
      height: 11.3125rem;
      margin-top: -4.125rem;
      margin-right: 0.75rem;
      transform: rotate(117deg);
      @media (max-width: 1650px) {
        width: 8.0625rem;
        height: 8.125rem;
        margin-top: -3.5625rem;
        margin-right: 2.375rem;
        transform: rotate(116deg);
      }
      @media (max-width: 550px) {
        width: 6.6875rem;
        margin-top: -3.25rem;
        margin-right: 3.125rem;
        height: 6.75rem;
        transform: rotate(117deg);
      }
    }
  }
`;

export const ShareBox = styled('SpeedDial')`
  .react-share__ShareButton {
    display: flex !important;
    align-item: center !important;
    background-color: #fff;
  }
  .css-11qgbbi {
    background-color: rgb(253, 253, 253);
  }
  .css-9p7v7w-MuiButtonBase-root-MuiFab-root-MuiSpeedDial-fab.MuiFab-root {
    background-color: rgb(253, 253, 253);
  }
  .css-uj3r6d.MuiFab-root {
    background-color: #fff;
  }
  .Article {
    bottom: 0rem !important;
    position: absolute;
    // background-color:#fff;
  }
  .share {
    bottom: 0rem !important;
    right: 2rem;
    // background-color:#fff;
    @media (max-width: 1650px) {
      right: 0rem;
    }
    // @media (max-width: 330px) {
    //   right: 0rem !important;
    // }
  }
  .Article1 {
    bottom: -3rem !important;
  }
`;
export const SpeedDialBox = styled('SpeedDial')`
  position: absolute;
  bottom: 7rem;
`;
export const Links = styled('Link')`
.link{

}
  
`;

export const PrintDiv = styled('div')`
  @media all {
    .page-break {
      display: none;
    }
  }
  @media print {
    html,
    body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }
  @media print {
    .page-break {
      margin-top: 1rem;
      display: block;
      page-break-before: auto;
    }
  }
  @page {
    size: auto;
    margin: 20mm;
  }
`;

export const Title = styled(Typography)`
  h1 {
    // p {
    font-size: 3rem;
    line-height: 4.5rem !important;
    font-family: ${FONT.FAMILY.CAMPAIGN} !important;
    @media (max-width: 1400px) {
      font-size: 2.188rem !important;
      line-height: 2.9rem !important;
    }
    @media (max-width: 550px) {
      font-size: 1.5rem !important;
      line-height: 2.7rem !important;
    }
    // }
  }
  span:first-of-type {
    background: ${`url(${Scribble}) bottom no-repeat`};
    background-size: contain;
    padding-bottom: 10px;
    @media (max-width: 550px) {
      padding-bottom: 6px;
    }
  }
  span:nth-of-type(2) {
    background-size: contain;
  }

  h2 {
    font-family: ${FONT.FAMILY.CAMPAIGN};
    font-weight: ${FONT.WEIGHT.MEDIUM};
    font-size: ${FONT.SIZES[40]};
    color: #241c15;
    line-height: 3.5rem;
    margin: 2.5rem 0 0.25rem 0;
    @media (max-width: 550px) {
      font-size: ${FONT.SIZES[24]};
      line-height: 2rem;
    }
  }
  p {
    font-family: ${FONT.FAMILY.ASSISTANT};
    font-size: ${FONT.SIZES[18]};
    color: #241c15;
    line-height: 1.68rem;
    .subtitle {
      font-family: ${FONT.FAMILY.CAMPAIGN};
      font-size: ${FONT.SIZES[20]};
    }
  }
  // p:first-of-type {
  //   font-family: ${FONT.FAMILY.CAMPAIGN};
  //   font-weight: ${FONT.WEIGHT.REGULAR};
  //   font-size: ${FONT.SIZES[24]};
  //   color: #241c15;
  //   line-height: 2.031rem;
  //   margin: 2.5rem 0;
  //   @media (max-width: 550px) {
  //     text-align: left;
  //     // font-size: ${FONT.SIZES[16]};
  //     font-family: ${FONT.FAMILY.ASSISTANT};
  //     margin: 1.5rem 0;
  //   }
  // }
  blockquote.blockquote1 {
    margin: 1rem 0.5rem;
    p {
      font-family: ${FONT.FAMILY.CAMPAIGN};
      font-weight: ${FONT.WEIGHT.REGULAR};
      font-size: ${FONT.SIZES[18]};
      text-align: left;
      line-height: 2rem;
      @media (max-width: 550px) {
        text-align: center;
      }
    }
  }
  blockquote.blockquote2 {
    font-family: ${FONT.FAMILY.CAMPAIGN};
    font-weight: ${FONT.WEIGHT.REGULAR};
    font-size: ${FONT.SIZES[18]};
    border-image: linear-gradient(#3f418a, #885ca1) 30;
    border-left: 3px solid;
    margin: 70px 0px;
    p {
      text-align: left;
      margin-left: 20px;
    }
    @media (max-width: 550px) {
      margin: 30px 0px;
    }
  }
  // blockquote:nth-of-type(3) {
  //   border-left: 3px solid #3f418a;
  //   margin: 70px 0px;
  //   p {
  //     text-align: left;
  //     margin-left: 20px;
  //   }
  // }
  img {
    width: 100%;
    @media (max-width: 550px) {
      width: 50%;
      height: auto;
    }
  }
  ol {
    padding-inline-start: 1.875rem;
    li {
      line-height: 1.687rem;
      font-size: 1.125rem;
      margin-bottom: 1.5rem;
    }
  }
  ul {
    padding-inline-start: 1.875rem;
    li {
      line-height: 1.687rem;
      margin-bottom: 1.5rem;
      font-size: 1.125rem;
    }
  }
  .button {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }

  .button span {
    padding: 8px 16px;
    border: none;
    background: none;
    outline: none;
  }

  .button svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    // @media (max-width: 550px) {
    //   height: 50%;
    // }
  }

  .button path {
    stroke: #000;
    stroke-width: 4px;
    stroke-dasharray: 0 1500;
  }

  .button path {
    animation: draw 1s forwards;
  }

  @keyframes draw {
    from {
      stroke-dasharray: 0 1500;
    }

    to {
      stroke-dasharray: 1500 1500;
    }
  }
`;
export const MenuNav = styled(Box)`
  height: 100vh;
  width: 425px;
  padding-right: 10px;
  background-color: #edebea;
  @media (max-width: 550px) {
    width: 425px;
  }
  @media (max-width: 390px) {
    width: 370px;
  }
  @media (max-width: 325px) {
    width: 320px;
  }
`;

export const CarouselBox = styled(Carousel)`
  .css-1m9128y {
    margin-top: 20px;
  }
`;
export const Frame = styled(Box)`
  margin-left: 1rem;
  img {
    width: 40px;
    height: 40px;
    @media (max-width: 550px) {
      width: 30px;
      height: 30px;
    }
  }
`;
export const SliderImage = styled(Box)`
  margin: 0 auto;
  width: 34rem;
  height: 34rem;
  @media (max-width: 550px) {
    width: 15rem;
    height: 15rem;
  }
  img {
    border-radius: 25px;
    border: 2px solid black;
  }
`;
export const Logo = styled(Box)`
  img {
    width: 30%;
    height: auto;
    @media (max-width: 550px) {
      width: 80%;
    }
  }
`;
export const LandingImage = styled(Box)`
  text-align: end;
  img {
    width: 100%;
    height: auto;
    mix-blend-mode: unset !important;
    @media (max-width: 1400px) {
      width: 80%;
      text-align: end;
    }
    @media (max-width: 990px) {
      width: 40%;
      text-align: end;
    }
  }
  @media (max-width: 550px) {
    text-align: center;
    img {
      width: 80%;
    }
  }
`;
export const StoryContainer = styled(Box)`
  img {
    width: 100%;
    height: 28rem;
  }
  @media (min-width: 1001px) and (max-width: 1400px) {
    img {
      width: 80%;
      height: 17rem;
    }
  }
`;
export const SanjayImage = styled(Box)`
  img {
    width: 100%;
  }
  @media (min-width: 1001px) and (max-width: 1400px) {
    img {
      width: 60%;
    }
  }
`;
export const CollaboratorImage = styled(Box)`
  align-item: center;
  justify-content: center;
  img {
    width: 90%;
  }
  @media (min-width: 1001px) and (max-width: 1400px) {
    img {
      width: 95%;
    }
  }
`;
export const MainContainer = styled(Box)`
  margin-top: 0rem;
  a{
    text-decoration:none;
    color:#000;
  }
  @media (min-width: 1001px) and (max-width: 1400px) {
    // margin-top: -0.3rem !important;
    img {
      // width:80%;
    }
  }
`;
export const ArticleLandingImage = styled(Box)`
  text-align: end;
  img {
    width: 90%;
    height: auto;
    @media (max-width: 990px) {
      width: 80%;
      text-align: end;
    }
  }
  @media (max-width: 550px) {
    text-align: center;
    img {
      width: 80%;
    }
  }
`;
export const AvatarImage = styled(Box)`
  text-align: end;
  cursor: pointer;
  border-radius: 50%;
`;
export const ArticleCardSection = styled(Box)`
  padding: 0rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: '30px 0px 0px 30px ';
  position: 'relative';

  @media (min-width: 1001px) and (max-width: 1400px) {
    padding: 2rem 0 !important;
    // width: 1000px;
    margin: 0 auto;
  }
  @media (min-width: 991px) and (max-width: 1000px) {
    padding: 2rem 0 !important;
  }
  @media (min-width: 551px) and (max-width: 990px) {
    padding: 2rem 0 !important;
  }
  @media (max-width: 550px) {
    padding: 2rem 0 !important;
  }
`;

export const Section = styled(Box)`
  padding: 4rem 0;
  height: 85vh;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: '30px 0px 0px 30px ';
  position: 'relative';
  z-index: 99;
  img {
    mix-blend-mode: multiply;
    .no_blend{
      mix-blend-mode: unset;
    }
    .icon{
      mix-blend-mode: unset;
    }
  }
  @media (min-width: 1401px) and (max-width: 1500px) {
    max-width: 1100px;
    margin: 0 auto;
  }
  @media (min-width: 1001px) and (max-width: 1400px) {
    height: 85vh !important;
    padding: 2rem 2rem !important;
    max-width: 1000px;
    margin: 0 auto;
  }
  @media (min-width: 991px) and (max-width: 1000px) {
    height: 85vh !important;
    padding: 2rem 0 !important;
  }
  @media (min-width: 551px) and (max-width: 990px) {
    height: auto !important;
    padding: 2rem 0 !important;
  }
  @media (max-width: 550px) {
    padding: 2rem 0 !important;
    height: 85vh !important;
    .icon{
      width:25px;
    }
  }
`;
export const CollaboratorsSection = styled(Box)`
  padding: 4rem 0;
  height: 85vh;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: '30px 0px 0px 30px ';
  img {
    mix-blend-mode: multiply;
  }
  @media (min-width: 1401px) and (max-width: 1500px) {
    max-width: 1100px;
    margin: 0 auto;
  }
  @media (min-width: 1001px) and (max-width: 1400px) {
    height: 85vh !important;
    padding: 2rem 3rem !important;
    max-width: 1000px;
    margin: 0 auto;
  }
  @media (min-width: 991px) and (max-width: 1000px) {
    height: auto !important;
    padding: 2rem 0 !important;
  }
  @media (max-width: 990px) {
    height: auto !important;
    padding: 2rem 0 !important;
  }
  @media (max-width: 550px) {
    height: auto !important;
    padding: 2rem 0 !important;
  }
`;
export const ImgBox = styled(Box)`
  margin:0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border:2px solid #4c4848;
  border-radius:5px;
  overflow hidden;
  padding:1px;
`;
export const Section1 = styled(Box)`
  padding: 4rem 0;
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    padding: 2rem 0;
    height: 500px !important;
    display: flex;
    align-items: center;
  }
  @media (max-width: 550px) : {
    padding: 2rem 0;
    height: 500px !important;
    display: flex;
    align-items: center;
  }
`;
export const StickyBoxs = styled(StickyBox)`
  top: 20px !important;
`;
export const ArticleLanding = styled(Box)`
  background-color: #c4bcb9;
  color: #000;
  border-radius: 30px 0px 30px 30px;
`;
export const ShareIconBox = styled(ShareIcon)`
  background-color: #c4bcb9;
  color: #000;
  border-radius: 30px 0px 30px 30px;
`;
export const AuthorLanding = styled(Box)`
  background-color: #3e418a;
  color: #fff;
  border-radius: 30px 0px 30px 30px;
`;
export const ShareLanding = styled(Box)`
  background-color: #22534f;
  color: #fff;
  border-radius: 30px 0px 30px 30px;
`;
export const ExpernceLanding = styled(Box)`
  background-color: #d98d6d;
  // color: #fff;
  border-radius: 30px 0px 30px 30px;
`;
export const DescriptionSection = styled(Box)`
  background-color: #edebea;
  border-radius: 30px 30px 30px 30px;
  img{
    mix-blend-mode: unset;
  }
  @media (max-width: 550px) {
    background-color: #edebea;
  }
`;
export const ArticleFilterSection = styled(Box)`
  background-color: #edebea;
  border-radius: 30px 30px 30px 30px;
  @media (max-width: 550px) {
    background-color: #fff;
  }
`;
export const ExperinceDragCard = styled(Card)`
  border-radius: 30px 30px 30px 30px;
  width: 550px;
    height: 550px;
    padding:25px;
    border-radius: 20px;
    display:flex;
    justify-content:center
    align-item:center;
    @media (max-width: 1650px) {
      width: 350px;
      height: 350px;
    }
    @media (max-width: 550px) {
      width: 300px;
      height: 300px;
      padding: 0px;
      margin: 0 auto;
    }
  
`;
export const ExperinceDrag = styled(Box)`
  border-radius: 30px 30px 30px 30px;
  img {
    width: 500px;
    height: 500px;
    border-radius: 20px;
    @media (max-width: 1650px) {
      width: 350px;
      height: 350px;
    }
    @media (max-width: 550px) {
      width: 300px;
      height: 300px;
      margin: 0 auto;
    }
  }
`;

export const ArticleDescriptionSection = styled(Box)`
  background-color: #edebea;
  border-radius: 30px 30px 30px 30px;
`;

export const StyledContainer = styled(Container)`
  background: ${`url(${Art}) bottom right no-repeat`};
  background-size: contain;
  height: 420px;
  @media (min-width: 991px) and (max-width: 1400px) {
    background: ${`url(${Art}) top right no-repeat`};
    background-size: contain;
    height: 35vh;
  }
  @media (max-width: 550px) {
    height: 450px;
    background-size: contain;
  }
`;
export const ValuesStack = styled(Stack)`
  height: 18rem;
  width: 15rem;
  @media (min-width: 1401px) and (max-width: 1680px) {
    height: 18rem;
    width: 13rem;
  }
  @media (min-width: 991px) and (max-width: 1400px) {
    height: 13rem;
    width: 10rem;
  }
  @media (min-width: 551px) and (max-width: 990px) {
    height: 22rem;
    width: 15rem;
  }
  @media (max-width: 550px) {
    width: 9rem;
    min-height: 12rem;
    margin-top: 10px;
  }
`;
export const ScrollDownStack = styled(Stack)`
  // paddingtop: 3rem;
  // @media (min-width: 991px) and (max-width: 1400px) {
  //   paddingtop: 0rem;
  // } // paddingtop: 3rem;
  @media (max-width: 1300px) {
    display:none !important;
  }
  // @media (min-width: 551px) and (max-width: 990px) {
  //   paddingtop: 1.5rem;
  // }
  // @media (max-width: 550px) {
  //   paddingtop: 2.5rem;
  // }
  // @media (min-width: 551px) and (max-width: 990px) {
  //   paddingtop: 1.5rem;
  // }
  // @media (max-width: 550px) {
  //   paddingtop: 2.5rem;
  // }
  z-index: 999;
`;
export const ExperniceStack = styled(Stack)`
  img {
    width: 70%;
  }
  @media (min-width: 991px) and (max-width: 1400px) {
    img {
      width: 50%;
    }
  }
  @media (min-width: 551px) and (max-width: 990px) {
    img {
      width: 11%;
    }
  }
  @media (max-width: 550px) {
    img {
      width: 10%;
    }
  }
`;
export const ScrollDownIcon = styled(IconButton)`
  width: 50px;
  height: 50px;
  img {
    mix-blend-mode: upset;
   
  }
  @media (min-width: 991px) and (max-width: 1400px) {
    width: 30px;
    height: 30px;
  }
  @media (min-width: 551px) and (max-width: 990px) {
    width: 25px;
    height: 25px;
  }
  @media (max-width: 550px) {
    width: 25px;
    height: 25px;
  }
`;
export const ValuesFrontCard = styled(Card)`
  border: 1px solid black;
  font-family: ${FONT.FAMILY.CAMPAIGN};
  font-weight: ${FONT.WEIGHT.MEDIUM};
  font-size: ${FONT.SIZES[40]};
  min-height: 14rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  cursor: pointer;
  @media (min-width: 991px) and (max-width: 1400px) {
    min-height: 10rem;
  }
  @media (min-width: 551px) and (max-width: 990px) {
    min-height: 10rem;
  }
  @media (max-width: 550px) {
    min-height: 12rem;
    margin-top: 10px;
  }
`;
export const ValuesBackCard = styled(Card)`
  border: 1px solid black;
  min-height: 14rem;
  align-items: center;
  // padding: 1rem 0;
  display: flex;
  justify-content: center;
  background-color: transparent;
  cursor: pointer;
  @media (min-width: 991px) and (max-width: 1400px) {
    // padding: 2rem 0;
    .MuiTypography-body1 {
      font-size: ${FONT.SIZES[10]};
      line-height: 16px !important;
    }
    min-height: 10rem;
  }
  @media (min-width: 551px) and (max-width: 990px) {
    // padding: 2rem 0;
    min-height: 10rem;
  }
  @media (max-width: 550px) {
    min-height: 12rem;
    margin-top: 10px;
    // padding: 2rem 0;
  }
`;
