import { CardContent, Grid, Typography, Box, Stack } from "@mui/material";

import React, { useState } from "react";
import ReactCardFlip from "react-card-flip";

import Divider from "../Assets/Images/line.png";
import values_1 from "../Assets/Images/Home/SP_Values.png";
import {
  CarouselBox,
  Section,
  ValuesBackCard,
  ValuesFrontCard,
  ValuesStack,
} from "../Themes/StyledComponent";

import data from "../db.json";

const Values = (props) => {
  const itemData = data.home.values;

  function Item(props) {
    return (
      <Box>
        <ValuesFrontCard classes={`${props.item.class}`}>
          <Grid
            container
            spacing={0}
            justifyContent="center"
            alignItems="start"
            px={{ xs: 2, sm: 2, md: 5 }}
          >
            <Grid item xs={12} sm={6} md={8} lg={7}>
              <Typography variant="h4" pb={1}>
                {props.item.title}
              </Typography>
              <Typography variant="h5">{props.item.blurb}</Typography>
            </Grid>
          </Grid>
        </ValuesFrontCard>
      </Box>
    );
  }

  return (
    <>
      <Section id={props.id} sx={{ overflow: "hidden" }}>
        <Box>
          <Grid
            container
            spacing={0}
            justifyContent="center"
            alignItems="start"
            px={{ xs: 2, sm: 2, md: 0 }}
          >
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
              >
                <Typography variant="h1" textAlign="center">
                  Our{" "}
                  <span
                    style={{
                      background: ` url(${Divider}) bottom no-repeat`,

                      paddingBottom: "05px",
                      backgroundSize: "contain",
                    }}
                  >
                    values{" "}
                  </span>
                </Typography>
                <Box ml={-1} mt={-2}>
                  <img src={values_1} alt="img" width="60rem" />
                </Box>
              </Stack>
              <Box py={{ xs: 2, sm: 2, md: 5 }}>
                <Typography
                  variant="body"
                  textAlign={{ xs: "left", md: "center" }}
                >
                  Our Core Values are guideposts. They help us decide how to
                  act, especially when we are at a crossroads. In each story, we
                  can see a Societal Thinking Core Value come alive.
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Box display={{ xs: "none", md: "block" }}>
            <Grid
              container
              spacing={{ md: -0, lg: -4, xl: 2 }}
              justifyContent={{ md: "space-between", xl: "center" }}
              // alignItems="center"
              sx={{ py: 5 }}
            >
              {itemData.map((item, index) => (
                <FlipCard index={index} item={item} />
              ))}
            </Grid>
          </Box>
          <Box display={{ xs: "block", md: "none" }}>
            <CarouselBox sx={{ p: 5 }}>
              {itemData.map((item, i) => (
                <Item key={i} item={item} />
              ))}
            </CarouselBox>
          </Box>
        </Box>
      </Section>
    </>
  );
};

export default Values;

const FlipCard = ({ index, item }) => {
  // const [isFlipped, setIsFlipped] = useState(false);
  // const [flipCard, setFlipCard] = useState(false);
  const [id, setId] = useState("");
  console.log("data...id === ", item.id === id, typeof item.id, typeof id);
  return (
    <Grid item xs={11} sm={2} md={2} lg={2} key={index}>
      <ValuesStack
        direction="row"
        justifyContent="center"
        alignItems={`${item.contentAlign}`}
      >
        <ReactCardFlip
          isFlipped={id === item.id}
          // isFlipped={isFlipped}
          flipDirection="horizontal"
          // id={item.id}
        >
          <ValuesFrontCard
            classes={item.class}
            onMouseEnter={() => {
              setId(item.id);
              // setFlipCard(true);
            }}
          >
            <CardContent variant={item.contentclass}>
              <Typography variant="h4">{item.title}</Typography>
            </CardContent>
          </ValuesFrontCard>

          <ValuesBackCard
            classes={`${item.class1}`}
            onMouseOut={() => {
              setId("");
              // setFlipCard(false);
            }}
          >
            <CardContent
              sx={{
                "&.MuiCardContent-root": {
                  paddingBottom: "10px",
                },
              }}
              variant={item.contentclass}
            >
              <Typography variant="body1">{item.blurb}</Typography>
            </CardContent>
          </ValuesBackCard>
        </ReactCardFlip>
      </ValuesStack>
    </Grid>
  );
};
