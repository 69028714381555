import { Box, Grid, Typography, Container } from '@mui/material';
import React from 'react';
import Authors from '../Assets/Images/Home/all-frames.png';
import {
  CollaboratorsSection,
  CollaboratorImage,
} from '../Themes/StyledComponent';
import Authors_mobile from '../Assets/Images/Home/all-frames-mobile.png';
import Divider from '../Assets/Images/stroke-scribble.png';
import Pen from '../Assets/Images/Home/SP_Collaborators.png';
import MuseButton from './MuseButton';

const Collaborators = (props) => {
  return (
    <>
      <CollaboratorsSection id={props.id} pt={{ xs: 4, sm: 4, md: 13 }}>
        <Container sx={{ position: 'relative' }}>
          <Grid
            container
            spacing={0}
            justifyContent="space-between"
            alignItems="start"
            py={{ xs: 4, sm: 4, md: 5 }}
          >
            <Grid item xs={12} sm={12} md={6} lg={5} sx={{ textAlign: "left" }}>
              <Typography variant="h2" textAlign="Left">
                <b>
                  {' '}
                  Our{' '}
                  <span
                    style={{
                      background: ` url(${Divider}) bottom no-repeat`,
                      paddingBottom: '16px',
                      backgroundSize: 'contain',
                    }}
                  >
                    collaborators
                  </span>
                  <img src={Pen} alt="img" width="10%" />
                </b>
              </Typography>
              <Box pt={{ xs: 2, sm: 2, md: 3 }} pb={{ xs: 2 }}>
                <Typography variant="body">
                  Our storytellers – a mix of social innovators, funders and
                  orchestrators – all have different lived experiences and yet,
                  the same quest: How do we induce exponential societal change?
                  Join them on their journey.
                </Typography>
              </Box>
              <Box
                mt={{ xs: 3, sm: 3, md: 4,lg:10 }}
                display={{
                  xs: 'none',
                  sm: 'Block',
                }}
              >
                <MuseButton title="See All" routePath={'routePath'} />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={7} sx={{ textAlign: "left" }}>
              <Box
                display={{
                  xs: 'block',
                  sm: 'none',
                  md: 'none',
                }}
              >
                <img src={Authors_mobile} width="100%" alt="img" />
                <Box
                  mt={{ xs: 3, sm: 3, md: 25 }}
                  justifyContent="flex-start"
                  display="flex"
                  
                >
                  <MuseButton title="See All" routePath={'routePath'} />
                </Box>
              </Box>
              <CollaboratorImage
                display={{
                  xs: 'none',
                  sm: 'Block',
                  md: 'flex',
                }}
              >
                <img src={Authors} width="100%" alt="img" />
              </CollaboratorImage>
            </Grid>
          </Grid>
        </Container>
      </CollaboratorsSection>
    </>
  );
};

export default Collaborators;
