import React, { useState } from 'react';
import {
  Grid,
  Typography,
  Container,
  List,
  ListItem,
  Stack,
  Box,
} from '@mui/material';

import Pen from '../Assets/Images/Home/SP_Stories.png';
import Bulleticon from '../Assets/Images/Home/SP_BulletPoint.png';

import Divider from '../Assets/Images/Vector 42.png';
import { Section, StoryContainer } from '../Themes/StyledComponent';

import Article1 from '../Assets/Images/Articles/spm-harambee-01.png';
import Article2 from '../Assets/Images/Articles/spm-reapbenefit-01.png';
import Article3 from '../Assets/Images/Articles/spm-citymart-01.png';
import Article4 from '../Assets/Images/Articles/spm-shikshalokam-01.png';
import Article5 from '../Assets/Images/Articles/spm-seeds-01.png';
import Article6 from '../Assets/Images/Articles/spm-echo-01.png';
import Article7 from '../Assets/Images/Articles/spm-egov-01.png';
import { Link } from "react-router-dom";

const Stories = (props) => {
  // const navigate = useNavigate();
  const Stories = [
    {
      id: '0',
      redirect: 14,
      slug: 'how-can-every-young-person-reach-their-potential-by-having-access-to-opportunities',
      icon: '1',
      title:
        "When society, state and <br> markets <span class='link'> work together</span>",
      image: Article1,
    },
    {
      icon: '2',
      redirect: 3,
      slug: 'can-anyone-who-keeps-the-street-clean-become-a-hero',
      id: '1',
      title: "<span class='link'>Solve small,</span> dent big",
      image: Article2,
    },
    {
      id: '2',
      redirect: 15,
      slug: 'how-can-governments-and-social-innovators-come-together-to-create-impact',
      icon: '3',
      title: "Striking <span class='link'>collaboration</span> gold",
      image: Article3,
    },
    {
      id: '3',
      redirect: 17,
      slug: 'how-can-education-leaders-improve-their-schools-consistently',
      icon: '4',
      title: "Don't Build again,build <span class='link'>beyond</span>",
      image: Article4,
    },
    {
      id: '4',
      redirect: 4,
      slug: 'how-can-we-all-thrive-in-a-rapidly-changing-planet',
      icon: '5',
      title: "<span class='link'>Reimagining</span> disaster resilience",
      image: Article5,
    },
    {
      id: '5',
      redirect: 5,
      slug: 'can-right-knowledge-at-the-right-place-at-the-right-time-save-millions-of-lives',
      icon: '6',
      title: "All <span class='link'>teach,</span> all learn",
      image: Article6,
    },
    {
      id: '6',
      redirect: 21,
      slug: 'how-can-we-make-governance-and-citizen-services-transparent-and-easily-accessible',
      icon: '7',
      title: "The power of <span class='link'>public goods</span>",
      image: Article7,
    },
  ];
  const [id, setId] = useState(0);

  const [hoveredItem, setHoveredItem] = useState(0);

  return (
    <Section id={props.id}>
      <Container sx={{ position: 'relative' }}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12} sm={6} alignItems="center">
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Typography variant="h2" textAlign="left">
                Our{' '}
                <span
                  style={{
                    background: ` url(${Divider}) bottom no-repeat`,
                    paddingBottom: '7px',
                    backgroundSize: 'contain',
                  }}
                >
                  Stories
                </span>
              </Typography>
              <Box mt={-3}>
                <img src={Pen} alt="img" width="60rem" class="" />
              </Box>
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            mt={{ xs: 1, sm: 4, md: 5 }}
            sx={{ textAlign: 'left' }}
          >
            <Typography variant="body" textAlign="left">
              Societal Muse is our annual publication comprising of stories of
              change. These stories ask you to pause, reflect and muse: What
              does it take to create impact at scale? We hope that these stories
              can also become your Muse: an inspiration for you to dream big and
              find pathways to realise your big vision.
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          pt={4}
        >
          <Grid
            item
            xs={12}
            sm={6}
            alignItems="flex-start"
            display={{ xs: 'none', md: 'block' }}
          >
            <StoryContainer px={5}>
              <img src={Stories[id].image} alt="img" />
            </StoryContainer>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <List pt={{ xs: 2, md: 11 }}>
              {Stories.map((item, index) => (
                <>
                  <ListItem
                    key={index}
                    id={item.id}
                    onMouseEnter={() => setId(item.id)}
                    sx={{
                      '&.MuiListItem-root': {
                        paddingBottom: '0.625rem',
                        '@media (max-width:1400px)': {
                          paddingBottom: '0.0625rem',
                        },
                      },
                    }}
                  >
                    <Typography
                      variant="caption"
                      onMouseEnter={() => setHoveredItem(index)}
                      className={hoveredItem === index && 'ActiveClass'}
                      active
                    >
                      <Stack
                        spacing={2}
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                      >
                        <img src={Bulleticon} alt="img" width="15rem" />

                        <Typography variant="h4">
                        <Link to={`/article?post=${item.redirect}&slug=${item.slug}`}>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item.title,
                            }}
                          />
                          </Link>
                        </Typography>
                        <Box
                          sx={{
                            width: '1rem',
                            '@media (min-width:440px)': { width: '2rem' },
                          }}
                          display={{ xs: 'none', md: 'block' }}
                        ></Box>
                      </Stack>
                    </Typography>
                  </ListItem>
                </>
              ))}
            </List>
          </Grid>
        </Grid>
      </Container>
    </Section>
  );
};
export default Stories;
