import { Box, Container, Grid, Typography } from '@mui/material';
import React from 'react';
import { CollaboratorsSection } from '../Themes/StyledComponent';
import Art2 from '../Assets/Images/Articles/spm-rohini-nilekjani.png';
import Art1 from '../Assets/Images/Articles/spm-dongips.png';
import Nilekani from '../Assets/Images/Home/logo-rnp.png';
import Skoll from '../Assets/Images/Home/logo-skoll.png';
import MuseButton from './MuseButton';

const InsightStories = (props) => {
  return (
    <>
      <CollaboratorsSection id={props.id}>
        <Container sx={{ position: 'relative' }}>
          <Grid
            container
            spacing={{ xs: 4, sm: 1 }}
            direction={{ xs: 'row' }}
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              textAlign="left"
              pb={3}
              px={{ xs: 2, md: 3 }}
            >
              <Box display={{ xs: 'block', sm: 'none' }}>
                <img src={Art1} width="100%" alt="img" />
              </Box>
              <Typography variant="overline">Special insight</Typography>
              <Typography
                variant="h1"
                textAlign="Left"
                color="text-white"
                pb={{ xs: 1, md: 2 }}
              >
                Let’s go farther,
                <br />
                <b>together </b>
              </Typography>

              <Typography variant="body1">
                <b>Donald Gips</b>
              </Typography>
              <Typography
                variant="body2"
                sx={{ textTransform: 'uppercase' }}
                pb={{ xs: 1, md: 1 }}
              >
                CEO, SKOLL FOUNDATION
              </Typography>
              <Box display={{ xs: 'none', md: 'block' }} pb={{ xs: 1, md: 2 }}>
                <img src={Skoll} width="80rem" alt="img" />
              </Box>
              <Grid
                container
                spacing={0}
                direction={{ xs: 'column-reverse', sm: 'row' }}
                alignItems={{ xs: 'flex-start', sm: 'flex-end' }}
                mt={{ xs: 2, sm: -19, md: -7 }}
              >
                <Grid item xs={12} sm={6} md={6} lg={6} textAlign="left">
                  <MuseButton
                    title="Read More"
                    id={16}
                    slug={
                      'how-system-orchestrators-create-social-change-symphonies'
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} textAlign="left">
                  <Box display={{ xs: 'none', sm: 'block' }}>
                    <img src={Art1} width="100%" alt="img" />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              textAlign="left"
              pb={3}
              px={{ xs: 2, md: 3 }}
            >
              <Box display={{ xs: 'block', sm: 'none' }}>
                <img src={Art2} width="100%" alt="img" />
              </Box>
              <Typography variant="overline">Special insight</Typography>
              <Typography variant="h1" textAlign="Left" pb={{ xs: 1, md: 2 }}>
                Let’s place
                <br />
                <b>bold bets </b>
              </Typography>

              <Typography variant="body1">
                <b>Rohini Nilekani</b>
              </Typography>
              <Typography
                variant="body2"
                sx={{ textTransform: 'uppercase' }}
                pb={{ xs: 1, md: 2 }}
              >
                Chairperson, Rohini <br />
                Nilekani Philanthropies
              </Typography>
              <Box display={{ xs: 'none', md: 'block' }}>
                <img src={Nilekani} width="100rem" alt="img" />
              </Box>
              <Grid
                container
                spacing={0}
                direction={{ xs: 'column-reverse', sm: 'row' }}
                alignItems={{ xs: 'flex-start', sm: 'flex-end' }}
                mt={{ xs: 0, sm: -12, md: -12, lg: -12 }}
              >
                <Grid item xs={12} sm={6} md={6} lg={6} textAlign="left" pb={3}>
                  <MuseButton
                    title="Read More"
                    id={6}
                    slug={'placing-bold-bets-trust-and-patience'}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} pb={3}>
                  <Box display={{ xs: 'none', sm: 'block' }}>
                    <img src={Art2} width="100%" alt="img" />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </CollaboratorsSection>
    </>
  );
};

export default InsightStories;
