import React, { useEffect } from 'react';
import Blurb2 from '../Components/Blurb2';
import Blurb from '../Components/Blurb';
import Blurb1 from '../Components/Blurb1';
import Collaborators from '../Components/Collaborators';
import Stories from '../Components/Stories';
import Values from '../Components/Values';
import InsightStories from '../Components/insight';
import ScrollDown from '../Components/ScrollDown';

const styles = {
  LandingBackground: {
    backgroundColor: '#E2D1ED',
  },
};

const Home = (props) => {
  useEffect(() => {
    onScrollHandler('landingSection');
  }, []);
// onscroll function when page loads
  const onScrollHandler = (id) => {
    const element = document.getElementById(id);

    if (id === 'landingSection') {
      element?.scrollIntoView({
        block: 'nearest',
        inline: 'start',
      });
    } else {
      element?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      });
    }
  };

  return (
    <>
      <div style={styles.LandingBackground}>
        <Blurb id="landingSection" />
        <ScrollDown
          scrollFunction={() => onScrollHandler('blurb1')}
          imageSrc="drak"
        />

        <Blurb1 id="blurb1" />
        <ScrollDown
          scrollFunction={() => onScrollHandler('storiesSection')}
          imageSrc="drak"
        />

        <Stories id="storiesSection" mb={-5} />
        <ScrollDown
          scrollFunction={() => onScrollHandler('insightStories')}
          imageSrc="drak"
        />

        <InsightStories id="insightStories" />
        <ScrollDown
          scrollFunction={() => onScrollHandler('blurb2')}
          imageSrc="drak"
        />

        <Blurb2 id="blurb2" />
        <ScrollDown
          scrollFunction={() => onScrollHandler('collaborators')}
          imageSrc="drak"
        />

        <Collaborators id="collaborators" />
        <ScrollDown
          scrollFunction={() => onScrollHandler('values')}
          imageSrc="drak"
        />

        <Values id="values" />
      </div>
    </>
  );
};

export default Home;
