import { Box, Container, Grid, Typography } from '@mui/material';
import React from 'react';

import {
  ArticleCardSection,
  ArticleDescriptionSection,
} from '../Themes/StyledComponent';
import dividerline from '../Assets/Images/Line 7.png';
import StoryCard from './StoryCard';

const AllArticlesCard = (props) => {
  return (
    <>
      <ArticleCardSection id={props.id}>
        <Container>
          <ArticleDescriptionSection>
            <Box display={{ xs: 'none', sm: 'block' }}>
              <img src={dividerline} width="100%" alt="img" />

              <Box pt={5}>
                <Typography variant="h6" sx={{ textAlign: 'left' }}>
                  <b> EDITION 01</b>
                </Typography>
                <Typography variant="h3" textAlign="Left">
                  Response. Responsibility. Resilience.
                </Typography>
              </Box>
            </Box>
            <Grid
              container
              spacing={0}
              justifyContent="space-evenly"
              alignItems="flex-start"
              py={5}
              px={{ xs: 0, sm: 0 }}
              id="Article_card"
            >
              {props.itemData.map((item, index) => (
                <Grid item xs={12} sm={6} md={4} lg={4} spacing={0}>
                  <StoryCard
                    title={item.title}
                    blurb={item.excerpt}
                    Author={item.author}
                    images={item.image}
                    id={item.authorID}
                    slug={item.slug}
                  />
                </Grid>
              ))}
            </Grid>
          </ArticleDescriptionSection>
        </Container>
      </ArticleCardSection>
    </>
  );
};

export default AllArticlesCard;
