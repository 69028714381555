import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, Route, Routes } from 'react-router-dom';

import { useLocation } from 'react-router';
import Footer from '../Components/Footer';
import Header from '../Components/Header';
import Home from '../Pages/Home';
import { MainContainer, StickyBoxs } from '../Themes/StyledComponent';

import { ThemeProvider } from '@mui/material/styles';
import theme from '../Themes/Theme';

import { Box, Grid, List, ListItem, ListItemButton } from '@mui/material';
import kid2 from '../Assets/Images/Home/sp-muse-kid-02.png';
import kid3 from '../Assets/Images/Home/sp-muse-kid-03.png';
import kid1 from '../Assets/Images/Home/sp-muse-kid-01.png';

import PropTypes from 'prop-types';
import IndiviualArticle from '../Pages/IndiviualArticle';
import AllArticles from '../Pages/AllArticles';

import Authors from '../Pages/Authors';
import database from '../db.json';
import Share from '../Pages/Share';
import Loader from '../Components/loader';
import Experince from '../Pages/Experince';
import Contact from '../Pages/Contact';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
const Layout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;

  const ref = useRef(null);

  const [value, setValue] = React.useState(0);
  const buttonHeight = `calc(80vh/5)`;
  const buttonName = database.menu;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 4500);
  }, []);

  const [scroll, setScroll] = useState();
  const handlescroll = (event) => {
    var winScroll = event.currentTarget.scrollTop;
    var height =
      event.currentTarget.scrollHeight - event.currentTarget.clientHeight;
    var scrolled = Math.floor((winScroll / height) * 100);
    setScroll(scrolled);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          {loading ? (
            <Loader />
          ) : (
            <>
              <ThemeProvider
                theme={theme}
                backgroundColor={path !== '/article' ? 'inherit' : '#E5E5E5'}
              >
                <Header
                  color={path !== '/article' ? 'inherit' : 'action'}
                  pb={2}
                />

                <MainContainer
                  sx={{
                    mt: { md: 1, lg: 0, xl: 1.1 },
                    pb: 5,
                    px: { xs: 0, sm: 0, md: 5 },
                  }}
                  backgroundColor={path !== '/article' ? 'inherit' : '#E5E5E5'}
                  // mt={ path !== "/article" ? 0 : 2}
                >
                  {path === '/' ? (
                    <Grid
                      container
                      spacing={0}
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid
                        item
                        xs={1}
                        sm={1}
                        md={1}
                        lg={1}
                        sx={{
                          position: 'absolute',
                          marginTop: '-1.6rem',
                          marginLeft: '8rem',
                          zIndex: 9999,
                          display: {
                            xs: 'none',
                            md: 'block',
                          },
                        }}
                      >
                        {path === '/' ? (
                          <img
                            src={kid1}
                            alt="img"
                            width="50%"
                            sx={{ mixBlendMode: 'multiply' }}
                          />
                        ) : (
                          ''
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        sm={1}
                        md={1}
                        lg={1}
                        sx={{
                          position: 'absolute',
                          marginTop: '6rem',
                          marginLeft: '79%',
                          display: {
                            xs: 'none',
                            md: 'block',
                          },

                          zIndex: 999,
                        }}
                      >
                        <img
                          src={kid2}
                          alt="img"
                          sx={{
                            position: 'fixed',
                            marginTop: '3rem',
                            marginLeft: '90%',
                            mixBlendMode: 'multiply',
                          }}
                          width="60%"
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    ''
                  )}
                  <Grid
                    container
                    spacing={0}
                    justifyContent="center"
                    alignItems="start"
                    sx={{ pt: 0 }}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={11}
                      lg={11}
                      sx={{ textAlign: 'center', zIndex: 99 }}
                    >
                      <Box>
                        <div
                          value={value}
                          index={0}
                          ref={ref}
                          style={{
                            overflowY: 'auto',
                            overflowX: 'hidden',
                            borderRadius: '30px 0px 30px 30px ',
                            height: '85vh',

                            zIndex: 999,
                            '@media (max-width:1400px)': {
                              height: 'auto',
                            },
                          }}
                          elevation={3}
                          className="body"
                          onScroll={handlescroll}
                        >
                          <Routes>
                            <Route path="/" element={<Home />} />
                            <Route
                              path="/article"
                              element={<IndiviualArticle scroll={scroll} />}
                            />
                            <Route path="/articles" element={<AllArticles />} />
                            <Route path="/authors" element={<Authors />} />
                            <Route path="/share" element={<Share />} />
                            <Route path="/experience" element={<Experince />} />
                            <Route path="/contact" element={<Contact />} />
                          </Routes>
                          {path === '/' ? (
                            <Grid
                              container
                              spacing={0}
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Grid
                                item
                                xs={1}
                                sm={1}
                                md={1}
                                lg={1}
                                sx={{
                                  mt: 1,
                                  ml: { xs: -15, md: -11, lg: -10, xl: -9 },
                                  position: 'absolute',
                                  bottom: {
                                    md: '2rem',
                                    lg: '4rem',
                                    xl: '4rem',
                                  },
                                  left: '8rem',
                                  display: {
                                    xs: 'none',
                                    md: 'block',
                                  },
                                }}
                              >
                                <img
                                  src={kid3}
                                  alt="img"
                                  width="60%"
                                  sx={{ mixBlendMode: 'multiply' }}
                                />
                              </Grid>
                            </Grid>
                          ) : (
                            ''
                          )}
                        </div>
                      </Box>
                    </Grid>

                    <StickyBoxs>
                      <Grid
                        item
                        xs={1}
                        sm={1}
                        md={1}
                        lg={1}
                        display={{ xs: 'none', md: 'block' }}
                      >
                        <List sx={{ paddingTop: 0, width: '100%' }}>
                          {buttonName.map((item, index) => (
                            <ListItem
                              disablePadding
                              key={index}
                              sx={{
                                paddingTop: 0,
                                width: '4rem',
                              }}
                            >
                              <ListItemButton
                                sx={{
                                  backgroundColor: item.backgroundColor,
                                  borderRadius:
                                    '0% 100% 100% 0% / 45% 50% 50% 45%;',
                                  writingMode: 'tb',
                                  color: item.color,
                                  fontSize: 12,
                                  fontWeight: 600,
                                  height: buttonHeight,
                                  disply: 'flex !important',
                                  justifyContent: 'center !important',
                                  alignItems: 'end !important',
                                  verticalAlign: 'center',
                                  boxShadow:
                                    item.link === path
                                      ? 'none'
                                      : '8px 2px 11px -1px rgb(0 0 0 / 15%) inset',

                                  '&:hover': {
                                    background: item.backgroundColor,
                                    cursor: 'pointer',
                                    opacity: '0.9',
                                  },
                                }}
                                onClick={() => {
                                  navigate(item.link);
                                }}
                              >
                                {item.name}
                              </ListItemButton>
                            </ListItem>
                          ))}
                        </List>
                      </Grid>
                    </StickyBoxs>
                  </Grid>
                </MainContainer>
                <Footer
                  color={path !== '/article' ? 'inherit' : '#E5E5E5'}
                  // color="red"
                />
              </ThemeProvider>
            </>
          )}
        </>
      )}
    </>
  );
};
export default Layout;
