import React from 'react';
import { useState } from 'react';
import {
  Grid,
  Typography,
  InputAdornment,
  IconButton,
  TextField,
  Box,
  Snackbar,
  Container,
  Stack, Slide
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import LinkedInIconImage from "../Assets/Images/Icons/icon-linkedin.png";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { LandingImage } from "../Themes/StyledComponent";
import Book from "../Assets/Images/sp-muse-read-header-1.png";
import MuiAlert from '@mui/material/Alert';
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import TwitterIconImage from '../Assets/Images/Icons/icon-twitter.png';

const useStyles = makeStyles(() => ({
  helperText: {
    color: "red",
    opacity: 1,
    fontSize: 14,
  },
}));

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Footer = (props) => {
  // const [open, setOpen] = useState(false);
  const [note, setNote] = useState("");
  const [error, setError] = useState(false);
  const [snackbar, setSnackbar] = useState()

  const classes = useStyles();

  const [state, setState] = React.useState({
    open: false,
    vertical: "bottom",
    horizontal: "center",
  });
  const handleClose = () => {
    setState({ ...state, open: false });
  };
  const { vertical, horizontal, open } = state;
  const [severity, setSeverity] = useState()


  const validationSchema = Yup.object().shape({
    Email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
  });
  const formik = useFormik({
    initialValues: { Email: "" },
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      let formEle = document.querySelector("form");
      const formdata = new FormData(formEle);
      let formNumber = 3044;
      const url = 'https://musedashboard.in/';
      axios
        .post(
          `${url}/wp-json/contact-form-7/v1/contact-forms/${formNumber}/feedback`,
          formdata
        )
        .then((res) => {
          setState({
            open: true,
            vertical: 'bottom',
            horizontal: 'center',
          });
          values.Email = ''
          setNote('success');
          setSnackbar('Thank you for subscribing');
        })
        .catch((err) => {
          console.log(err);
        });
    },
  });

  return (
    <>
      <Box sx={{ backgroundColor: props.color }}>
        <Container>
          <Grid
            container
            spacing={0}
            direction="row"
            justifyContent="center"
            alignItems="center"
            columns={12}
          >
            <Grid
              item
              xs={12}
              sm={6}
              md={5}
              lg={5}

              pr={{ xs: 0, md: 5 }}
            >
              <Typography
                variant="h4"
                textAlign="left"
                pb={3}
                pr={{ xs: 2, md: 5 }}
              >
                Societal Muse will be back with Edition 2! Stay tuned to read
                more stories of change leaders around the world applying
                Societal Thinking to induce exponential change.
              </Typography>
              <Box>
                <form
                  className="form"
                  onSubmit={(e) => {
                    //
                    e.preventDefault();
                    formik.handleSubmit();


                  }}
                >
                  <TextField
                    value={formik.values.Email}
                    onChange={formik.handleChange}
                    autoComplete='off'
                    name="Email"
                    fullwidth
                    placeholder="  YOUR EMAIL ADDRESS"
                    variant="outlined"
                    helperText={error}
                    FormHelperTextProps={{
                      className: classes.helperText,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton aria-label="forward"
                          >

                            <ArrowForwardIcon
                              style={{
                                color: 'black',
                              }}
                              onClick={formik.handleSubmit}

                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />{' '}
                  {formik.errors.Email && formik.touched.Email ? (
                    <div style={{ color: 'red' }}>{formik.errors.Email}</div>
                  ) : null}
                </form>
                <div>
                  {' '}
                  <Stack spacing={2} sx={{ maxWidth: 600 }}>
                    <Snackbar
                      anchorOrigin={{ vertical, horizontal }}
                      open={open}
                      autoHideDuration={6000}
                      onClose={handleClose}
                      message={snackbar}
                      key={vertical + horizontal}
                      TransitionComponent={(props) => {
                        return <Slide {...props} direction="up" />
                      }}
                    >
                      <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
                        {snackbar}
                      </Alert>
                    </Snackbar>
                  </Stack>
                </div>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={6}

              display={{ xs: 'none', sm: 'block' }}
            >
              <LandingImage>
                <img src={Book} alt="img" width="70%" />
              </LandingImage>
            </Grid>
          </Grid>
          <Box pt={{ xs: 2, md: 5 }}>
            <Grid
              container
              direction={{ md: 'row' }}
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                pt={{ xs: 2, sm: 3, md: 0 }}
              >
                <Typography variant="body1" textAlign="left">
                  All our work is under the{' '}
                  <span style={{ textDecoration: 'underline' }}>
                    Creative Commons licence, CC BY SA 4.0 International.
                  </span>{' '}
                  We invite you to share, build upon and remix our work and{' '}
                  <b>take the knowledge farther.</b>
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={5}
                lg={5}
                pl={{ xs: 0, sm: 3, md: 8 }}
                pt={{ xs: 2, sm: 3, md: 0 }}
              >
                <Typography variant="body" sx={{ mb: 0 }}>
                  <a href="mailto:info@societalthinking.org">info@societalthinking.org</a>
                </Typography>
                <Stack spacing={0}
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center">
                  <Box width="2rem" pt={1}>
                    <a href="https://www.linkedin.com/company/societal-platform/" target="_blank">
                      <img src={LinkedInIconImage} width="70%" alt="img" />
                    </a>
                  </Box>
                  <Box width="2rem" pt={1}>
                    <a href="https://x.com/SocietalThinkng" target="_blank">
                      <img src={TwitterIconImage} width="70%" alt="img" />
                    </a>
                  </Box>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
};
export default Footer;
