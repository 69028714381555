import React from 'react';
import { HashRouter } from 'react-router-dom';

import ReactGA from 'react-ga4';
import Layout from './Layout/Layout ';
import './App.css';
const TRACKING_ID = process.env.REACT_APP_TRACKING_ID // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

ReactGA.send({ hitType: "pageview", page: "/my-path" });




function App() {
  return (
    <>
      <HashRouter>
          <Layout />
      </HashRouter>
    </>
  );
}

export default App;
