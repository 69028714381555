import { Box, Container, Grid, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import Verticleline from '../Assets/Images/varticleLine.png';
import LinkedInIconImage from '../Assets/Images/Icons/icon-linkedin-collabs.png';
import WebSiteIconImage from '../Assets/Images/Icons/icon-website.png';

import MuseButton from './MuseButton';
import { ArticleCardSection } from '../Themes/StyledComponent';

import useMuseButton from '../Hooks/useMuseButton';

const AuthorDetails = (props) => {
  const { museButton, onMouseEnter, onMouseLeave } = useMuseButton();
  const LongText = ({ content, limit }) => {
    const [showAll, setShowAll] = useState(false);
    const showMore = () => setShowAll(true);
    const showLess = () => setShowAll(false);
    if (content.length <= limit) {
      return <div>{content}</div>;
    }
    if (showAll) {
      return (
        <div>
          {content} <br></br>
          <a href={showLess}>
            <b>
              <u>See less</u>
            </b>
          </a>
        </div>
      );
    }
    // In the final case, we show a text with ellipsis and a `Read more` button
    const toShow = content?.substring(0, limit) + '...';
    return (
      <div>
        {toShow}
        <br />
        <a onClick={showMore}>
          <b>
            <u>See more</u>
          </b>
        </a>
      </div>
    );
  };

  return (
    <>
      <ArticleCardSection>
        <Container>
          <Box py={5} id={props.id}>
            {props.author.map((item, index) => (
              <Grid
                container
                spacing={0}
                justifyContent="space-evenly"
                alignItems="center"
                my={5}
                py={5}
                px={{ xs: 1, sm: 3, md: 5 }}
                sx={{
                  backgroundColor: '#fff',
                  borderRadius: '15px',
                }}
                id={item.id}
              >
                <Grid item xs={12} sm={3} md={3} lg={3}>
                  <img
                    src={item.img}
                    width="70%"
                    sx={{ border: '2px solid black', borderRadius: '' }}
                    alt="img"
                  />
                </Grid>
                <Grid
                  item
                  xs={1}
                  sm={1}
                  md={1}
                  lg={1}
                  textAlign={{ xs: 'center', md: 'left' }}
                  display={{ xs: 'none', sm: 'block' }}
                >
                  <Box></Box>
                  <img src={Verticleline} height="220.5rem" alt="img" />
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={8}
                  md={8}
                  lg={8}
                  textAlign={{ xs: 'center', md: 'left' }}
                >
                  <Box display={{ xs: 'block', sm: 'none' }}>
                    <Typography variant="body">
                      <LongText content={item.blurb} limit={80} />
                    </Typography>
                  </Box>
                  <Box display={{ xs: 'none', sm: 'block' }}>
                    <Typography variant="body">{item.blurb}</Typography>
                  </Box>
                  <br />
                  <Stack
                    direction="row"
                    justifyContent={{ xs: 'center', sm: 'flex-start' }}
                    alignItems="center"
                    spacing={1}
                  >
                    <MuseButton
                      title="Read Article"
                      postId={item.id}
                      museButton={museButton}
                      onMouseEnter={onMouseEnter}
                      onMouseLeave={onMouseLeave}
                    />
                    {item?.linked !== '' && item?.linked !== undefined ? (
                      <Box width="3rem">
                        <a href={item.linked} target="_blank" rel="noreferrer">
                          <img src={LinkedInIconImage} width="100%" alt="img" />
                        </a>
                      </Box>
                    ) : (
                      ''
                    )}
                    {item?.website !== '' && item?.website !== undefined ? (
                      <Box width="3rem">
                        <a href={item.website} target="_blank" rel="noreferrer">
                          <img src={WebSiteIconImage} width="100%" alt="img" />
                        </a>
                      </Box>
                    ) : (
                      ''
                    )}
                  </Stack>
                </Grid>
              </Grid>
            ))}
          </Box>
        </Container>
      </ArticleCardSection>
    </>
  );
};

export default AuthorDetails;
