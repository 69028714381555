import { Box, Container, Typography } from "@mui/material";
import React from "react";
import Graph from "../Assets/Images/Home/SP_graph_Icon.png";
import Coin from "../Assets/Images/Home/SP_Coins icon.png";
import Bulb from "../Assets/Images/Home/SP_lightbulb Icon.png";
import Questionmark from "../Assets/Images/Home/SP_Question mark icon.png";
import { Section } from "../Themes/StyledComponent";

const Blurb = (props) => {
  return (
    <>
      <Section id={props.id}>
        <Container sx={{ position: "relative" }}>
          <Box px={{ xs: 0, sm: 5, md: 10 }} pt={{ xs: 5, sm: 0 }}>
            <Typography variant="h3" textAlign="center">
              Societal Muse is our annual publication that shows stories of{" "}
              <b>
                solving at scale <img class="icon" src={Graph} alt="img" width="30rem" />
              </b>{" "}
              in action. These are stories of{" "}
              <b>
                big bold bets
                <img class="icon" src={Coin} alt="img" width="30rem" />
              </b>
              of reimagination, of perseverance, of coming together, of{" "}
              <Typography variant="link">
                <b>building agency  </b>
              </Typography>
                through the eyes of
              practitioners and supporters of{" "}
              <Typography variant="link">Societal Thinking.</Typography>
            </Typography>
            <br />
            <Typography variant="h3">
              Societal Muse offers{" "}
              <b>
                new answers
                <img  class="icon" src={Bulb} alt="img" width="35rem" />
              </b>
              , new ways of doing and sometimes{" "}
              <b>
                new questions
                <img class="icon" src={Questionmark} alt="img" width="35rem" />
              </b>{" "}
              too.
            </Typography>
          </Box>
        </Container>
      </Section>
    </>
  );
};

export default Blurb;
