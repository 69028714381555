import React from 'react';
import {
  Typography,
  AppBar,
  Toolbar,
  CssBaseline,
  useScrollTrigger,
  Box,
  Fab,
  Fade,
  Grid,
  Button,
  Stack,
  Slide,
  List,
  ListItem,
  ListItemButton,
  Drawer,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';

import ScorllUp from '../Assets/Images/ScorllUp.png';

import PropTypes from 'prop-types';
import logo from '../Assets/Images/spm-logo.png';
import frame from '../Assets/Images/Frame.png';
import data from '../db.json';
import MenuIcon from '@mui/icons-material/Menu';
import { Frame, Logo, MenuNav } from '../Themes/StyledComponent';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import CloseIcon from '@mui/icons-material/Close';
import LinkedInIconImage from '../Assets/Images/Icons/icon-linkedin.png';

function ScrollTop(props) {
  const { children, window } = props;

  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-to-top-anchor'
    );

    if (anchor) {
      anchor.scrollIntoView({
        block: 'center',
      });
    }
  };

  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
      >
        {children}
      </Box>
    </Fade>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,

  window: PropTypes.func,
};

function HideOnScroll(props) {
  const { children, window } = props;

  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,

  window: PropTypes.func,
};
const buttonName = data.menu;
const Header = (props) => {
  const location = useLocation();
  const path = location.pathname;

  const navigate = useNavigate();

  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <MenuNav
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Stack
        direction="column"
        justifyContent="space-between"
        spacing={2}
        sx={{ backgroungColor: '#EDEBEA' }}
      >
        <Box>
          <Grid
            container
            spacing={0}
            alignItems="center"
            justifyContent="flex-start"
          >
            <Grid item xs={2} sm={3} md={3} lg={3}>
              <Frame>
                <KeyboardBackspaceIcon />
              </Frame>
            </Grid>
            <Grid
              item
              xs={7}
              sm={6}
              md={6}
              lg={6}
              justifyContent="center"
              sx={{ textAlign: 'center' }}
              alignItems="center"
            >
              <Logo pt={1}>
                <img src={logo} alt="img" />
              </Logo>
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3} textAlign="end">
              <CloseIcon />
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            backgroundColor: '#fff',
            mx: 2,
            borderRadius: '12px',
            pt: 2,
            pr: 2,
          }}
          style={{ marginLeft: '1rem' }}
        >
          <List>
            {buttonName.map((item, index) => (
              <ListItem disablePadding key={index}>
                <ListItemButton
                  sx={{
                    backgroundColor: item.backgroundColor,
                    borderRadius: '0 50px 50px 0',
                    color: item.color,
                    fontWeight: '800',
                    textAlign: 'center',
                    height: 100,
                    justifyContent: 'center',
                    paddingRight: '1rem',
                    '&:hover': {
                      backgroundColor: item.backgroundColor,
                      opacity: 0.9,
                    },
                  }}
                  onClick={() => navigate(item.link)}
                >
                  {item.name}
                </ListItemButton>
              </ListItem>
            ))}
            <ListItem disablePadding>
              <ListItemButton
                sx={{
                  backgroundColor: '#fff',
                  borderRadius: '0 50px 50px 0',
                  border: '1px solid Black',
                  color: '#000',
                  fontWeight: '800',
                  textAlign: 'center',
                  height: 100,
                  justifyContent: 'center',
                  paddingRight: '1rem',
                  '&:hover': {
                    backgroundColor: '#fff',
                    opacity: 0.9,
                  },
                }}
                onClick={() => navigate('/contact')}
              >
                CONTACT
              </ListItemButton>
            </ListItem>
          </List>
          <Box pt={15} px={3}>
            <a href="mailto:info@societalthinking.org">
              info@societalthinking.org
            </a>
            <Typography variant="h5"></Typography>
            <Box width="2rem" py={1}>
              <a
                href="https://in.linkedin.com/in/purohitmsanjay "
                target="_blank" rel="noreferrer"
              >
                <img src={LinkedInIconImage} width="100%" alt="img" />
              </a>
            </Box>
          </Box>
        </Box>
      </Stack>
    </MenuNav>
  );

  return (
    <>
      <React.Fragment>
        <CssBaseline />
        <AppBar elevation={0} className="AppBorder" color={props.color} pt={3}>
          <Box px={{ xs: 2, md: 8 }} py={{ xs: 2, sm: 0 }}>
            <Grid
              container
              spacing={0}
              alignItems="center"
              justifyContent="flex-start"
            >
              <Grid item xs={2} sm={3} md={3} lg={3}>
                <Frame>
                  <a href="https://societalthinking.org/" target="_blank" rel="noreferrer">
                    {/* <KeyboardArrowLeftIcon /> */}
                    <img src={frame} alt="img" />
                  </a>
                </Frame>
              </Grid>
              <Grid
                item
                xs={7}
                sm={6}
                md={6}
                lg={6}
                justifyContent="center"
                sx={{ textAlign: 'center', cursor: 'pointer' }}
                alignItems="center"
              >
                <Logo
                  onClick={() => {
                    navigate('/');
                    window.scrollTo(0, 0);
                  }}
                >
                  <img src={logo} alt="img" />
                </Logo>
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={3} textAlign="end">
                <Box display={{ xs: 'block', md: 'none' }} textAlign="end">
                  {['Menu'].map((anchor) => (
                    <React.Fragment key={anchor}>
                      <Button
                        onClick={toggleDrawer(anchor, true)}
                        variant="Menu-btn"
                      >
                        <MenuIcon />
                      </Button>
                      <Drawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                      >
                        {list(anchor)}
                      </Drawer>
                    </React.Fragment>
                  ))}
                </Box>
                {path !== '/contact' ? (
                  <Box display={{ xs: 'none', md: 'block' }}>
                    <Button
                      variant="outlined"
                      sx={{
                        color: '#000',
                        borderColor: '#000',
                        borderRadius: '25px',
                        fontWeight: 600,
                        px: 5,
                        mr: 12,
                        py: 1,
                      }}
                      mr={5}
                      onClick={() => {
                        navigate('/contact');
                        window.scrollTo(0, 0);
                      }}
                    >
                      CONTACT
                    </Button>
                  </Box>
                ) : (
                  ''
                )}
              </Grid>
            </Grid>
          </Box>
        </AppBar>
        <Toolbar id="back-to-top-anchor" />
        <Box></Box>
        <ScrollTop {...props}>
          <Fab size="small" aria-label="scroll back to top" variant='circular'>
            <img src={ScorllUp} alt="img" />
          </Fab>
        </ScrollTop>
      </React.Fragment>
    </>
  );
};

export default Header;
