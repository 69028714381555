import {  Container, Grid, Typography } from "@mui/material";
import React from "react";
import Divider from "../Assets/Images/Vector 6.png";
import Book from "../Assets/Images/sp-muse-read.png";
import { LandingImage, Section } from "../Themes/StyledComponent";

const ArticleLandingPage = (props) => {
  return (
    <>
      <Section id={props.id} pt={{ xs: 10, sm: 14, md: 13 }}>
        <Container sx={{ position: "relative" }}>
          <Grid
            container
            spacing={0}
            direction={{ xs: "column-reverse", sm: "row" }}
            justifyContent="space-between"
            alignItems="center"
            pt={{ xs: 10, sm: 0, md: 0 }}
          >
            <Grid item xs={12} sm={12} md={5} lg={5} textAlign="left" pb={3}>
              <Typography
                variant="h1"
                textAlign="Left"
                color="text-white"
                pb={5}
              >
                Discover,
                <br />
                <b>
                  read and{" "}
                  <span
                    style={{
                      background: ` url(${Divider}) bottom no-repeat`,
                      paddingBottom: "05px",
                      backgroundSize: "contain",
                    }}
                  >
                    share
                  </span>
                </b>
              </Typography>
              <Typography variant="body" sx={{ fontSize: "1.25rem" }}>
                These are stories of change, of reimagination, of thinking
                audacious, of betting big. The first edition of Societal Muse
                brings together stories of Response. Responsibility. Resilience.
                Read these stories, muse about them and if they inspire you the
                way they inspire us, share them with your network. Let’s usher
                in a brave new world, together.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={5} textAlign="left">
              <LandingImage>
                <img src={Book} alt="img" width="100%" />
              </LandingImage>
            </Grid>
          </Grid>
         
        </Container>
      </Section>
    </>
  );
};
export default ArticleLandingPage;
