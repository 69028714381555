const FONT = {
  FAMILY: {
    ASSISTANT: "montserrat",
    MONTSERRAT_SEMIBOLD:"Montserrat_Regular",
    CAMPAIGN: ["Campaign", "montserrat"].join(","),
  },
  SIZES: {
    8: "0.5rem",
    10: "0.625rem",
    12: "0.75rem",
    14: "0.875rem",
    15:"0.938rem",
    16: "1rem",
    18: "1.125rem",
    20: "1.25rem",
    22: "1.375rem",
    24: "1.5rem",
    26: "1.625rem",
    28: "1.75rem",
    30: "1.875rem",
    32: "2rem",
    34: "2.125rem",
    36: "2.25rem",
    38: "2.375rem",
    40: "2.5rem",
    44: "2.75rem",
    48: "3rem",
    64: "4rem",
  },
  WEIGHT: {
    BOLD: 900,
    SEMI_BOLD: 700,
    DEMI_BOLD: 600,
    MEDIUM: 500,
    REGULAR: 400,
    LIGHT: 300,
  },
};
export default FONT;
