import React, { useState, useEffect } from "react";
import ShareCard from "../Components/ShareCard";
import ShareLandingPage from "../Components/ShareLandingPage";
import { DescriptionSection, ShareLanding } from "../Themes/StyledComponent";
import axios from "axios";
import ScrollDown from "../Components/ScrollDown";
const Share = () => {
  const [itemData, setItemData] = useState([]);
  const url = process.env.REACT_APP_API_URL;
  const [error, setError] = useState("");
  useEffect(() => {
    onScrollHandler("landingSection");
    locationState();
  }, []);
//getting the api data to render data
  const locationState = async () => {
    try {
      await axios.get(`${url}wp/v2/posts?&_embed`).then((response) => {
        let articles = response.data;
        let cards = [];
        for (let index = 0; index < articles.length; index++) {
          const element = articles[index];
          const parser = new DOMParser();
          const contentForTitle = parser.parseFromString(
            element.title.rendered,
            "text/html"
          );
          const title = contentForTitle.body.innerText;
          const excerptContent = parser.parseFromString(
            element.excerpt.rendered,
            "text/html"
          );
          const excerpt = excerptContent.body.innerText.replace("\n", "");
          let fImage = element._embedded["wp:featuredmedia"]
            ? element._embedded["wp:featuredmedia"]["0"].source_url
            : "";
          let card = {
            title,
            excerpt,
            image: fImage,
            author: element._embedded["author"][0].name,
            authorID: element._embedded["author"][0].id,
            slug: element.slug,
            image_gif:element.acf?.image_gif,
            pdf:element.acf?.pdf
          };
          cards = [...cards, card];
        }
        let articlesOrderBySlug = process.env.REACT_APP_SORT;
        const sortedArticles = cards.sort((cardA, cardB) => {
          const indexA = articlesOrderBySlug.split(",").indexOf(cardA.author);
          const indexB = articlesOrderBySlug.split(",").indexOf(cardB.author);
          return indexA - indexB;
        });

        setItemData(sortedArticles);
      });
    } catch (e) {
      setError(e);
    }
  };
  const onScrollHandler = (id) => {
    const element = document.getElementById(id);
    if (id === "landingSection") {
      element?.scrollIntoView({
        block: "nearest",
        inline: "start",
      });
    } else {
      element?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }
  };

  useEffect(() => {
    onScrollHandler("landingSection");
  }, []);
  return (
    <>
      <ShareLanding pb={5}>
        <ShareLandingPage
          id="landingSection"
         
        />
        <ScrollDown
                scrollFunction={() => onScrollHandler("shareCard")}
                imageSrc=""
            />
      </ShareLanding>
      
      <DescriptionSection>
        <ShareCard id="shareCard" itemData={itemData} />
      </DescriptionSection>
    </>
  );
};

export default Share;
