import React from "react";
import { Grid, Typography, Container } from "@mui/material";
import { LandingImage, Section } from "../Themes/StyledComponent";
import Divider from "../Assets/Images/underline-white.png";
import Pen from "../Assets/Images/img-pen-white.png";
import art from "../Assets/Images/Artwork1.png";

const AuthorsLandingPage = (props) => {
  return (
    <>
      <Section id={props.id} pt={{ xs: 4, sm: 14, md: 13 }}>
        <Container sx={{ position: "relative" }}>
          <Grid
            container
            spacing={0}
            justifyContent="start"
            alignItems="center"
            direction={{ xs: "column-reverse", sm: "row" }}
            pt={{ xs: 4, sm: 4, md: 5 }}
          >
            <Grid item xs={12} sm={12} md={6} lg={6} sx={{ textAlign: "left" }}>
              <Typography variant="h1" textAlign="Left" pb={3}>
                <b>
                  {" "}
                  Our{" "}
                  <span
                    style={{
                      background: ` url(${Divider}) bottom no-repeat`,
                      paddingBottom: "05px",
                      backgroundSize: "contain",
                    }}
                  >
                    collaborators
                  </span>
                  <img src={Pen} alt="img" width="10%" class="icon" />
                </b>
              </Typography>
              <Typography variant="body" py={{ xs: 2, sm: 2, md: 18 }} mr={5}>
                Our storytellers – a mix of social innovators, funders and
                orchestrators – all have different lived experiences and yet,
                the same quest: How do we induce exponential societal change?
                Join them on their journey.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={{ textAlign: "left" }}>
              <LandingImage>
                <img src={art} width="100%" alt="img" />
              </LandingImage>
            </Grid>
          </Grid>
          
        </Container>
      </Section>
    </>
  );
};

export default AuthorsLandingPage;
