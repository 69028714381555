import React from 'react';
import ButtonIcon from '../Assets/Images/Icons/icon-arrow-down.png';
import ButtonIconWhite from '../Assets/Images/Icons/icon-arrow-down-white.png';
import { ScrollDownIcon, ScrollDownStack } from '../Themes/StyledComponent';

const ScrollDown = (props) => {
  return (
    <>
      <ScrollDownStack
        direction="row"
        justifyContent="center"
        alignItems="flex-end"
        spacing={5}
        display={{ xs: 'none', md: 'block' }}
        sx={{ mt: { xs: -7, sm: 0, md: -5, lg: -7, xl: -7 } }}
      >
        {props.imageSrc === 'drak' ? (
          <ScrollDownIcon
            onClick={props.scrollFunction}
          >
            <img src={ButtonIcon} alt="img" width="40px" />
          </ScrollDownIcon>
        ) : (
          <ScrollDownIcon
            onClick={props.scrollFunction}
          >
            <img src={ButtonIconWhite} alt="img" width="40px" />
          </ScrollDownIcon>
        )}
      </ScrollDownStack>
    </>
  );
};

export default ScrollDown;
