import { Box, Container, Grid, Typography } from '@mui/material';
import React from 'react';
import Blub from '../Assets/Images/Home/SP_lightbulb Icon.png';
import Sanjay from '../Assets/Images/Home/sp-muse-sp.png';
import { SanjayImage, Section } from '../Themes/StyledComponent';
import MuseButton from './MuseButton';

const Blurb_1 = (props) => {
  return (
    <>
      <Section id={props.id} py={{ xs: 4, sm: 4, md: 5 }}>
        <Container sx={{ position: 'relative' }} px={{ xs: 0, md: 10, xl: 0 }}>
          <Grid
            container
            spacing={0}
            justifyContent="start"
            alignItems="start"
            py={{ xs: 2, md: 5 }}
          >
            <Grid item xs={12} sm={12} md={7} lg={7} textAlign="left">
              <Typography variant="overline">Introduction</Typography>
              <Typography variant="h1" textAlign="Left">
                Embedded in each <br /> story, you will find
              </Typography>
              <Typography variant="h1" textAlign="Left">
                <b>
                  a small simple{' '}
                  <img class="icon" src={Blub} alt="img" width="40rem" />
                  idea.
                </b>
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={{ xs: 0, sm: 2, md: 6 }}
            justifyContent="flex-start"
            alignItems="start"
            pt={0}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={5}
              lg={5}
              textAlign="left"
              pb={3}
              pr={9}
            >
              <Typography variant="h4">
                Small simple ideas are like children. They need to be nurtured
                to take shape, scale and create impact.
              </Typography>
              <Box mt={5} display={{ xs: 'none', md: 'block' }}>
                <MuseButton title="READ More" id={7} slug={'sanjay-purohit'} />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={7} lg={7} textAlign="left" pr={3}>
              <Typography variant="body">
                Across the website you will meet many children. Dressed up and
                posing as adults, they are here to remind us that impact at
                scale can be achieved if we retain a sense of possibility,
                curiosity and imagination. They stand for small simple ideas
                that restore the agency of all.
              </Typography>
              <Grid
                container
                spacing={0}
                justifyContent="space-between"
                alignItems="start"
                pt={0}
              >
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={4}
                  lg={4}
                  textAlign="left"
                  py={{ xs: 2, md: 5 }}
                >
                  {' '}
                  <Typography variant="body1">
                    <b> Sanjay Purohit</b>
                  </Typography>
                  <Typography variant="body2">CHIEF CURATOR</Typography>
                  <Box mt={5} display={{ xs: 'block', md: 'none' }}>
                    <MuseButton
                      title="READ More"
                      id={7}
                      slug={'sanjay-purohit'}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={4} textAlign="right">
                  <SanjayImage>
                    <img src={Sanjay} alt="img" width="100%" />
                  </SanjayImage>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Section>
    </>
  );
};

export default Blurb_1;
